import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Router } from "./Router";
import { Theme } from "./Theme";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import { store } from "./Redux-toolkit/Store";
<script src="https://cdn.jsdelivr.net/npm/react/umd/react.production.min.js" crossorigin></script>

const root = createRoot(document.getElementById("root"));


root.render(

  <BrowserRouter>
    <ThemeProvider theme={Theme}>
      <Provider store={store}>

        <Router />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);
