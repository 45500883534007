import {
    Grid,
    Button,
    Typography,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FormControl,
    Select,
    MenuItem,
    RadioGroup,
    Radio,
    InputAdornment,
} from "@mui/material";
import React from "react";
import { Sidebar } from "../../components/Sidebar";
// import './InjurySummary.css';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import { useNavigate } from "react-router-dom";

const InjurySummary = () => {
    const navigate = useNavigate()
    return (
        <>
            <Sidebar>
                <Grid m={3}>
                    <Typography>Injury Report Summary</Typography>
                    <Typography>&nbsp;</Typography>
                    <Grid container px={2} py={5} sx={{ backgroundColor: 'white', border: '2px solid #bdbdbd', borderRadius: 1 }}>
                        <Grid container className='d-flex justify-content-end '>
                            <Grid item md={8} sm={8} xs={12} sx={{ height: '100%' }}>
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginRight: '8px' }}> + </Button>
                                <TextField
                                    id="input-with-icon-textfield"

                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginLeft: '8px' }}> <AutoAwesomeMotionIcon /> </Button>
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginLeft: '8px' }}> <DeleteIcon /> </Button>

                            </Grid>
                        </Grid>
                        <Grid container my={3} className='d-flex justify-content-center'>
                            <Grid item md={5} lg={5} xs={8} className='d-flex justify-content-between'>
                                <Typography variant="h4">Summary</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item md={4}>
                                <Typography>Name of Injured</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox color="info" size="small" />} label="Child" />
                                </FormGroup>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>Name of Injured</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <TextField id="outlined-basic" label="Name" variant="outlined" size="small" />
                            </Grid>
                        </Grid>

                        <Grid container mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={4}>
                                <Typography>Date/Time</Typography>
                            </Grid>
                            <Grid item>
                                <TextField type='date' size='small' sx={{ minWidth: 235 }} />
                            </Grid>
                            <Grid item sx={{ display: 'flex' }} ml={1}>
                                <FormControl sx={{ minWidth: 90 }} size='small'>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value="11"
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        <MenuItem value='11'>11</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ display: 'flex' }}>
                                <FormControl sx={{ minWidth: 90 }} size='small'>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value="00"
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        <MenuItem value='00'>00</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ display: 'flex' }}>
                                <FormControl sx={{ minWidth: 90 }} size='small'>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value="AM"
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        <MenuItem value='AM'>AM</MenuItem>
                                        <MenuItem value="PM">PM</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={4}>
                                <Typography>Team member incharge during the incident</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <TextField id="outlined-basic" label="Name" variant="outlined" size="small" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={4}>
                                <Typography>Was first aid provided</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="yes" control={<Radio size="small" color="info" />} label="Yes" />
                                </RadioGroup>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>Was further medical treatment provided? (eg: was ambulance/doctor on call ?)</Typography>
                            </Grid>
                            <Grid item md={8} mt={4}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="yes" control={<Radio size="small" color="info" />} label="Yes" />
                                </RadioGroup>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>Serious injury</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="yes" control={<Radio size="small" color="info" />} label="Yes" />
                                </RadioGroup>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>Head/Facial</Typography>
                            </Grid>
                            <Grid item md={8}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="yes" control={<Radio size="small" color="info" />} label="Yes" />
                                </RadioGroup>
                            </Grid>
                            <Grid item md={4}>
                                <Typography>Does regulatory authorities need to be notified?</Typography>
                            </Grid>
                            <Grid item md={4} mt={3}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel value="yes" control={<Radio size="small" color="info" />} label="Yes" />
                                </RadioGroup>
                            </Grid>
                            <Grid item color='red' md={4} mt={3}>
                                <Typography>*Please enter this field*</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item md={2}>
                                <Typography>Name of injured person</Typography>
                            </Grid>
                            <Grid item >
                                <TextField id="outlined-basic" className="w-100" variant="outlined" size="small" />
                            </Grid>

                            <Grid item md={2}>
                                <Typography>Location-Witness/Station</Typography>
                            </Grid>
                            <Grid item >
                                <TextField id="outlined-basic" className="w-100" placeholder="xyza" variant="outlined" size="small" />
                            </Grid>
                            <Grid item mt={2} md={4}>
                                <Typography>Responsible person</Typography>
                            </Grid>
                            <Grid item mt={2} md={4}>
                                <TextField id="outlined-basic" className="w-100" variant="outlined" size="small" />
                            </Grid>
                            <Grid item mt={2} md={4}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox color="info" />} label="Me" />
                                </FormGroup>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={3}>
                                <img src="https://t3.ftcdn.net/jpg/02/38/00/56/360_F_238005627_QgvAPoIEet2f14EAZHt0CqAmyLCByqIE.jpg" alt="" />
                            </Grid>
                            <Grid item md={9}>
                                <TextField id="outlined-basic" label="Describe the story..." className="w-100" variant="outlined" size="large" multiline rows={6} style={{ backgroundColor: '#e0e0e0' }} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={3}>
                                <Typography>First aid provider</Typography>
                            </Grid>
                            <Grid item md={9}>
                                <TextField id="outlined-basic" placeholder="Name" variant="outlined" size="small" />
                            </Grid>
                            <Grid item md={3}>
                                <Typography>First aid location</Typography>
                            </Grid>
                            <Grid item md={9}>
                                <TextField id="outlined-basic" placeholder="Location" variant="outlined" size="small" />
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Medical practioner name</Typography>
                            </Grid>
                            <Grid item md={9}>
                                <TextField id="outlined-basic" placeholder="Name" variant="outlined" size="small" />
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Medical practioner number</Typography>
                            </Grid>
                            <Grid item md={9}>
                                <TextField id="outlined-basic" placeholder="0123456789" variant="outlined" size="small" />
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Should parent be notified</Typography>
                            </Grid>
                            <Grid item md={9}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="yes" control={<Radio size="small" color="info" />} label="Yes" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item md={3}>
                                <Typography>Responsible person name</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <TextField id="outlined-basic" className="w-100" placeholder="Pin Entered" variant="outlined" size="small" multiline rows={2} />
                            </Grid>

                            <Grid item sm={5} mt={1}>
                                <Typography>Root cause</Typography>
                            </Grid>
                            <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField id="outlined-basic" label="Describe the story..." variant="outlined" multiline rows={2} sx={{ minWidth: 400 }} />
                                <MicIcon />
                            </Grid>
                            <Grid item mt={3}>
                                <Typography>Safety precautions</Typography>
                            </Grid>
                            <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField id="outlined-basic" label="Describe the story..." variant="outlined" multiline rows={2} sx={{ minWidth: 400 }} />
                                <MicIcon />
                            </Grid>
                            <Grid item mt={3}>
                                <Typography>Recommendations</Typography>
                            </Grid>
                            <Grid item sm={12} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField id="outlined-basic" label="Describe the story..." variant="outlined" multiline rows={2} sx={{ minWidth: 400 }} />
                                <MicIcon />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2}>
                            <Grid item sm={5}>
                                <Typography>Parent notification status</Typography>
                            </Grid>
                            <Grid item sm={7}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="eml" control={<Radio size="small" color="info" />} label="Email Sent" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item sm={5}>
                                <Typography>Parent acknowledgement status</Typography>
                            </Grid>
                            <Grid item sm={7}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="ackn" control={<Radio size="small" color="info" />} label="Acknowledgement received" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item sm={5}>
                                <Typography>Should parent be notified</Typography>
                            </Grid>
                            <Grid item sm={7}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="yes" control={<Radio size="small" color="info" />} label="Yes" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Grid container sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Grid item mt={6} >
                                <Button sx={{ mx: 2 }} className="cancel_btn" onClick={() => navigate('/injury')}>Back</Button>
                                <Button className="btn_primary">Submit</Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Sidebar>
        </>
    );
};
export default InjurySummary;