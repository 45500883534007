import { Add, Edit, ExpandLess, Search } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { baseURL } from "../baseURL";
import axios from "axios";
import { io } from "socket.io-client"

export const Routines = () => {
  
  let id = localStorage.getItem("id");
  var socket = io('https://childcare-714af9baa16a.herokuapp.com', {
      auth: {
          id
      },

  })
  let [loader, setLoader] = useState(true);
  let [allRooms, setAllRooms] = useState([]);
  let [all, setAll] = useState([]);
  let [noData, setNoData] = useState(false);

  function getAllChild() {
    setNoData(false);
    let url = `${baseURL}teacher/getTeacher/${id}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.data.length !== 0) {
          console.log(res.data.data[0].room_data);
          setAllRooms(res.data.data[0].room_data);
          setAll(res.data.data[0].room_data);
          setLoader(false);
        } else {
          setLoader(false);
          setNoData(true);
        }
      })
      .catch((err) => console.log(err));
  }

  function onSearch(e) {
    let { value } = e.target;
    setAllRooms(
      all.filter((r) => r.room_name.toLowerCase().includes(value.toLowerCase().trim()))
    );
  }

  useEffect(() => {
    getAllChild();
  }, []);

  return (
    <Sidebar>
      {loader ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box sx={{ mx: 2 }}>
          {noData ? (
            <Box>No data available...</Box>
          ) : (
            <Box>
              <Grid container my={3}>
                <Grid item md={5} sm={5} xs={12}>
                  <TextField
                    id="input-with-icon-textfield"
                    placeholder="Search Name"
                    size="medium"
                    onChange={onSearch}
                    sx={{  width:'100%',bgcolor:'white' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Table sx={{bgcolor:'white',boxShadow: "0 0 5px 0 #888888"}}>
                <TableHead>
                  <TableRow>
                    <TableCell>Sno.</TableCell>
                    <TableCell>Room Name</TableCell>
                    <TableCell>Number of Childs</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allRooms.length!==0?allRooms.map((res, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{res.room_name}</TableCell>
                        <TableCell>{res.room_childArray.length}</TableCell>
                        <TableCell>
                          <Link style={{textDecoration:'none'}} to={`/allchildforroutine/${res._id}`}>
                            <Button className="btn_primary">Visit Room</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  }):<Typography m={2}>No Records Found</Typography>}
                </TableBody>
              </Table>
            </Box>
          )}
        </Box>
      )}
    </Sidebar>
  );
};
