import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  Backdrop,
  useMediaQuery,
} from "@mui/material";
import React, { useContext } from "react";
import { Sidebar } from "../../components/Sidebar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import storage from "../../firebase/firebase";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "../../style.css";
import { contextData } from "../../Router";
import { SnackBar } from "../SnackBar";
import styles from "./teacher.module.css";

const TeacherDetails = () => {
  var navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const smallScreen = useMediaQuery('(max-width:576px)');
  const [teacher, setTeacher] = useState("");
  const [gender, setGender] = useState("");
  const [teacher_lastname, SetLastName] = useState("")
  const [age, setAge] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [qualification, setQualification] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [imageURL, setImageURL] = useState({ link: "", name: "" });
  let [loader, setLoader] = useState(false);

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  let date = new Date();
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let year = date.getFullYear();

  let fullDate = `${year}-${month}-${day}`;
  let checkSnackbar = useContext(contextData);



  function handlesubmit(e) {
    e.preventDefault()
    const data = {
      teacher_name: teacher,
      teacher_gender: gender,
      teacher_age: 0,
      teacher_dob: dob,
      teacher_lastname,
      pin: pin,
      child_care_center: localStorage.getItem("childcare_center"),
      teacher_email: email,
      teacher_number: phonenumber,
      teacher_qualification: qualification,
      teacher_address: address,
      tenant_id: localStorage.getItem("tenant_id"),
      teacher_login_credentials: {
        teacher_login_email: "",
        teacher_password: "",
      },
    };
    console.log(data);

    axios
      .post(Apis.EDUCATOR.add, data)
      .then((res) => {
        console.log(res.data.data);

        if (res.data.data.teacher_name !== undefined) {
          console.log(data);
          checkSnackbar.setState(true);
          navigate("/educators");
          setLoader(false);
        } else {
          setLoader(false);
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: res.data.data,
            type: "warning",
          });

          if (url !== 'no') {
            const storage = getStorage();
            const desertRef = ref(storage, url);
            deleteObject(desertRef).then(() => {
              console.log("image deleted");
            })
          }

        }
      })
      .catch((err) => {
        setLoader(false);

        console.log('ooo')



        console.log(err.response.data.errormessage.details[0].message);
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: err.response.data.errormessage.details[0].message,
          type: "warning",
        });
      });
  }

  //for File Upload
  function inpChange(e) {
    let image = e.target.files[0];
    console.log(image, "kok0k00o0o0oo");
    setImageURL({ link: URL.createObjectURL(image), name: image });
  }
  function removeImage() {
    setImageURL({ link: "", name: "" });
  }

  return (
    <>
      <Sidebar>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid px={3}>
          <Link to="/educators">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        <Typography sx={{ textAlign: "center", my: 4 }} variant="h5">
          {" "}
          Add Educator
        </Typography>
        <Grid container paddingX={6}>
          <form onSubmit={handlesubmit}>



            <div className="row m-0 bg-white p-md-5 p-3">

              <div className="col-md-6 col-12 p-2">
                <Typography>First Name</Typography>
                <TextField
                  type="text"
                  autoComplete="off"
                  fullWidth
                  id="outlined-basic"
                  size="medium"
                  className="w-100"
                  // value={teacher}
                  placeholder="First Name"
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setTeacher(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Last Name</Typography>
                <TextField
                  type="text"
                  autoComplete="off"
                  id="outlined-basic"
                  size="medium"
                  fullWidth
                  className="w-100"
                  // value={teacher}
                  placeholder="Last Name"
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value == " "
                      ? (e.target.value = "")
                      : SetLastName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Address</Typography>
                <TextField
                  id="outlined-basic"
                  type="text"
                  size="medium"
                  className="w-100"
                  name="address"
                  fullWidth
                  // value={address}
                  required
                  placeholder="Enter Address"
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setAddress(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>DOB</Typography>
                <input
                  onChange={(e) => {
                    setDob(e.target.value);
                  }}
                  required
                  style={{ height: "57px" }}
                  name="dob"
                  className={`form-control m-0 inp_field w-100 ${styles.dob_input}`}
                  type="date"
                  max={fullDate}
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Email</Typography>
                <TextField
                  id="outlined-basic"
                  type="email"
                  name="email"
                  autoComplete="off"
                  className="w-100"
                  required
                  placeholder="Enter Email"
                  variant="outlined"
                  onChange={(e) => {
                    console.log(e.target.value);
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 col-12 p-2">


                <Typography>Phone Number</Typography>
                <TextField
                  id="outlined-basic"
                  type="number"
                  size="medium"
                  className="w-100"
                  name="phonenumber"
                  autoComplete="off"
                  required
                  // value={phonenumber}
                  placeholder="Enter Phone Number"
                  variant="outlined"
                  onChange={(e) => {
                    setPhonenumber(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Qualification</Typography>
                <TextField
                  id="outlined-basic"
                  type="text"
                  size="medium"
                  name="qualification"
                  // value={qualification}
                  className="w-100"
                  required
                  placeholder="Enter Qualification"
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setQualification(e.target.value);
                  }}
                />


              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Pin</Typography>
                <TextField
                  id="outlined-basic"
                  size="medium"
                  type="text"
                  className="w-100"
                  inputProps={{ maxLength: 4, minLength: 4 }}
                  required
                  placeholder="Enter Pin"
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setPin(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Gender</Typography>
                <FormControl
                  fullWidth
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="gender"
                    label="Enter Gender"
                    required
                    fullWidth
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </FormControl>
              </div>


            </div>











            <Grid
              item
              md={12}
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                sx={{
                  ml: "10px",
                  textTransform: "none",
                  mr: "8px",
                }}
                onClick={() => {
                  navigate("/educators");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="info"
                type="submit"
                sx={{ textTransform: "none" }}
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Grid>
      </Sidebar>
    </>
  );
};
export default TeacherDetails;
