import { Typography, Grid } from "@mui/material";
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Sidebar } from "../../components/Sidebar";
import { baseURL } from "../baseURL";
import { Link, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getLatestAge } from "../getLatestAge";
import { ProfileLogoName } from "../../components/ProfileLogoName";

const Childview = () => {
  const { id } = useParams();
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  var [childData, setChildData] = useState([]);
  useEffect(() => {
    axios.get(`${baseURL}child/getChild/${id}`).then((res) => {
      setChildData(res.data.data);
      console.log(res.data.data);
    });
  }, []);

  return (
    <>
      <Sidebar>
        <Grid px={3}>
          <Link to="/childlisting">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        {childData.map((item, index) => {
          return (
            <Grid
              key={index}
              sx={{ backgroundColor: "#EFF6FF", padding: "60px" }}
            >
              <Grid sx={{ backgroundColor: "white", padding: "30px" }}>
                <Grid container>
                  <Grid item>
                    {/* <ProfileLogoName firstName={item.child_name} lastName={item.child_lastname} /> */}
                  </Grid>
                  <Grid item pt={1} pl={2}>
                    <Typography sx={{ fontSize: "30px" }}>
                      Child Details
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>Child Name :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {item.child_name} {item.child_lastname}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>Father Name :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {item.data.length == 0 ? '----' : item.data[0].father_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>Mother Name:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {item.data.length == 0 ? '----' : item.data[0].mother_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>Gender :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {item.child_gender}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>Age :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {getLatestAge(item.child_dob)} years
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>DOB :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {item.child_dob}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>Address :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {item.data.length == 0 ? '----' : item.data[0].parent_home_address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>Years :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {getLatestAge(item.created_at)} years
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container pt={2}>
                  <Grid item md={4}>
                    <Typography>Configuration Age :</Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "#1799CE" }}>
                      {(item.child_age)} years
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Sidebar>
    </>
  );
};

export default Childview;
