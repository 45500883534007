import * as React from "react";
import Form from 'react-bootstrap/Form';
import { Sidebar } from "../../components/Sidebar";
import {

    Grid,
    Button,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    FormLabel,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox
} from "@mui/material";

const Accident = () => {

    return (
        <Sidebar>
            <Grid m={3}>
                <Grid container px={3} py={4} sx={{ backgroundColor: "white", border: '2px solid #bdbdbd', borderRadius: 1 }}>
                    <Grid container spacing={2} >
                        <Grid item md={6}>
                            <TextField id="outlined-basic" label="Service Name" className="w-100" variant="outlined" size='small' />
                        </Grid>
                        <Grid item md={6}>
                            <TextField id="outlined-basic" label="Nominated Supervisor Name" className="w-100" variant="outlined" size='small' />
                        </Grid>
                    </Grid>
                    <Grid container mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item>
                            <Typography>Time of Inquiry</Typography>
                        </Grid>
                        <Grid item ml={2} sx={{ minWidth: 144 }}>
                            <TextField type='date' size='small' />

                        </Grid>
                        <Grid item sx={{ display: 'flex' }} ml={2}>
                            <FormControl sx={{ minWidth: 144 }} size='small'>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value="11"
                                >
                                    <MenuItem value="">
                                    </MenuItem>
                                    <MenuItem value='11'>11</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sx={{ display: 'flex' }}>
                            <FormControl sx={{ minWidth: 144 }} size='small'>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value="00"
                                >
                                    <MenuItem value="">
                                    </MenuItem>
                                    <MenuItem value='00'>00</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sx={{ display: 'flex' }}>
                            <FormControl sx={{ minWidth: 144 }} size='small'>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value="AM"
                                >
                                    <MenuItem value="">
                                    </MenuItem>
                                    <MenuItem value='AM'>AM</MenuItem>
                                    <MenuItem value="PM">PM</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container mt={2} style={{ backgroundColor: '#e0e0e0' }}>
                        <Grid item md={9} >
                            <Grid container rowSpacing={3} sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
                                <Grid item sm={4}>
                                    <Typography>Name of Injured</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <FormControl className="w-100" size='small'>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value="Name"
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            <MenuItem value='Name'>Name</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item sm={4}>
                                    <Typography>Age</Typography>
                                </Grid>
                                <Grid item sm={8} >
                                    <TextField id="outlined-basic" label="Auto load based on the team member" className="w-100" variant="outlined" size='small' />
                                </Grid>
                                <Grid item sm={4}>
                                    <Typography>Child Classroom</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <TextField id="outlined-basic" label="Auto load based on the team member" className="w-100" variant="outlined" size='small' />
                                </Grid>
                                <Grid item sm={4}>
                                    <Typography>Room Lead</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <TextField id="outlined-basic" label="Auto load based on the team member" className="w-100" variant="outlined" size='small' />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item md={3} mt={2}>
                            <FormGroup >
                                <FormControlLabel control={<Checkbox color="info" />} label="Team Member" sx={{ mb: 1 }} />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox color="info" />} label="Enrolled Child" sx={{ mb: 1 }} />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox color="info" />} label="Parent" sx={{ mb: 1 }} />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox color="info" />} label="Student/Visitor" />
                            </FormGroup>
                        </Grid>
                    </Grid>


                    <Grid container spacing={2} mt={2} height="100%">
                        <Grid item md={4}>
                            <img src="https://t3.ftcdn.net/jpg/02/38/00/56/360_F_238005627_QgvAPoIEet2f14EAZHt0CqAmyLCByqIE.jpg" alt="Skelton" />
                         <Grid item mt={2}> 
                         <FormControl className="w-100" size="small">
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value="Select"
                                >
                                    <MenuItem value="">
                                    </MenuItem>
                                    <MenuItem value='Select'>Select</MenuItem>
                                </Select>
                            </FormControl>
                         </Grid>
                        </Grid>

                        <Grid item md={8} >
                            <Grid container spacing={2}>
                                <Grid item md={10}>
                                    <TextField id="outlined-basic" label="Where did the incident /accident occur?..." className="w-100" variant="outlined" size="small" />
                                </Grid>
                                <Grid item md={2}>
                                    <Button className="btn_primary btn">+ Add</Button>
                                </Grid>
                            </Grid>

                            <Grid item mt={1}>
                                <Typography>Team Member incharge of area at the time of incident:</Typography>
                                <FormControl className="w-100" size="small" sx={{ mb: 1 }}>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value="Select"
                                    >
                                        <MenuItem value="">
                                        </MenuItem>
                                        <MenuItem value='Select'>Select</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container>
                                <Grid item md={6}>
                                    <Typography>Team member who witnessed</Typography>
                                    <FormControl className="w-100" size="small" sx={{ mb: 1 }}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value="Select"
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            <MenuItem value='Select'>Select</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl className="w-100" size="small" sx={{ mb: 1 }}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value="Select"
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            <MenuItem value='Select'>Select</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl className="w-100" size="small">
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value="Select"
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            <MenuItem value='Select'>Select</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} px={1}>
                                    <Typography>Supervision Station</Typography>
                                    <FormControl className="w-100" size="small" sx={{ mb: 1 }}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value="Select"
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            <MenuItem value='Select'>Select</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl className="w-100" size="small" sx={{ mb: 1 }}>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value="Select"
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            <MenuItem value='Select'>Select</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl className="w-100" size="small">
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value="Select"
                                        >
                                            <MenuItem value="">
                                            </MenuItem>
                                            <MenuItem value='Select'>Select</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        <Typography>Please provide a factual amount of what occured during the incident/accident while child is noticed to be missing ( Be very specific and detailed including duration of missing child ) please provide description of incident and injury if possible</Typography>
                        <TextField id="outlined-basic" className="w-100" variant="outlined" size="large" multiline rows={5} style={{ backgroundColor: '#e0e0e0' }} />
                    </Grid>

                    <Grid container mt={4} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Typography>Team member signature</Typography>
                        <Grid item marginLeft={1} marginRight={2}>
                            <TextField id="outlined-basic" className="w-100" variant="outlined" size="small" />
                        </Grid>
                        <Grid item >
                            <Button className="btn_primary btn">Save</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Sidebar >

    );
};
export default Accident;