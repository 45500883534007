import { Search } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";

export const AssignRoomToChild = () => {
  let { id } = useParams();
  let tenant_id = localStorage.getItem("tenant_id");
  let [allRooms, setAllRooms] = useState([]);
  let [assignedRoom, setAssignedRoom] = useState("");
  let navigate = useNavigate();
  let [loader, setLoader] = useState(true);
  let [all,setAll]=useState([])
  useEffect(() => {
    getAllRooms();
  }, []);

  function getAllRooms() {
    let url = `${baseURL}room/getAll/${tenant_id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        setAllRooms(res.data.data);
        setAll(res.data.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }

  function assignRoom() {
    setLoader(true);
    let url = `${baseURL}child/addRoomsIntoChild/${id}`;
    axios
      .put(url, { child_room: [assignedRoom] })
      .then((res) => {
        setLoader(false);
        navigate("/childlisting");
        console.log(res);
      })
      .catch((err) => console.log(err));
  }
  function searchRoom(e){
    setAllRooms(all.filter(r=>r.room_name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase())))
  }

  return (
    <Sidebar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Typography sx={{ my: 3 }} align="center">
        Assign Room
      </Typography>
      <Grid container m={2} p={1}>
        <Grid item md={4} sm={7} xs={10}>
          <TextField
          onChange={searchRoom}
            id="input-with-icon-textfield"
            placeholder="Search anything"
            className="searchInput"
            size="medium"
            sx={{ backgroundColor: "white" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ mx: 3, bgcolor: "white" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Room Name</TableCell>
              <TableCell>Number of Childs</TableCell>
              <TableCell>Number of Educators</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allRooms.map((res, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={assignedRoom == res._id}
                      color="info"
                      type="checkbox"
                      onClick={() => setAssignedRoom(res._id)}
                    />
                  </TableCell>
                  <TableCell>{res.room_name}</TableCell>
                  <TableCell>{res.room_childArray.length}</TableCell>
                  <TableCell>{res.room_teacherArray.length}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <Grid container px={6}>
        <Grid
          item
          mt={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="other"
            sx={{ border: "1px solid #FF0000", color: "red" }}
            onClick={() => navigate("/childlisting")}
          >
            Cancel
          </Button>
          <Button
            variant="other"
            sx={{
              background: "#1799CD",
              color: "#ffffff",
              border: "1px solid #1799CD",
              marginLeft: "10px",
            }}
            onClick={assignRoom}
          >
            Add Room
          </Button>
        </Grid>
      </Grid>
    </Sidebar>
  );
};
