import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../baseURL";
import { CChart } from "@coreui/react-chartjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ArrowBackIos, LocalParking } from "@mui/icons-material";
import { Line, Pie } from "react-chartjs-2";
import { Apis } from "../../auth/GlobalUrl";
import moment from "moment";

export const ChildGraph = () => {
    let { id } = useParams()
    let [loader, setLoader] = useState(true)
    console.log(id)
    let navigate = useNavigate()
    let dt = new Date();
    const [childFiveYearProgress, setChildFiveYearProgress] = useState({})
    let yr = dt.getFullYear();
    let mn = dt.getMonth() + 1;
    let fullmonth = `${yr}-${mn}`;
    const childData = useRef({})
    const [tableColumns, setTableColumns] = useState([])
    const [pieChartData, setPieChartData] = useState({})
    const allColors = ['#4472C4', '#FFCC30', '#ED7D31', '#D1CFCE', '#FFCC30']
    const allActivities = useRef(['presented', 'introduction'])
    let refDate = useRef({ month: mn, year: yr });
    const [value, setValue] = React.useState(dayjs(fullmonth));

    useEffect(() => {
        getChild()
        getBaseLine()



        let rrr = moment([2021, 4, 1]).diff(moment([2020, 1, 1]), 'months', true)
        console.log(rrr)








    }, [])
    const [baseLine, setBaseLine] = useState([])

    let tenant_id = localStorage.getItem('tenant_id')

    async function getBaseLine() {
        try {
            let res = await axios.get(Apis.BASELINE.get + tenant_id)
            setBaseLine(res.data.data[0].data.map(e => e.activity))
            console.log(res.data.data[0].data.map(e => e.activity))

        }
        catch (err) {
            console.log(err)
        }
    }


    async function getChild() {
        try {
            axios.all(
                [
                    axios.get(`${baseURL}child/getChild/${id}`),
                    axios.get(`${baseURL}activity/get/allActivity/${tenant_id}`),
                    axios.get(`${baseURL}child/getChildDashboardData/${id}`),
                    axios.post(`${baseURL}child//getChilPieChartData/${tenant_id}`, { id }),

                ])
                .then(axios.spread((data1, data2, data3, data4) => {

                    childData.current = data1.data.data[0].child_assessment
                    allActivities.current = data2.data.data.map(e => e.activity_name)

                    if (data3.data.data !== undefined) {
                        setChildFiveYearProgress(data3.data.data)
                    }
                    if (data4.data.data !== undefined) {
                        setPieChartData(data4.data.data)
                    }
                    showGraph(
                        { month: mn, year: yr },
                        data1.data.data[0].child_assessment
                    );
                }));
        }
        catch (err) {
            console.log(err)
        }
    }

    async function showGraph(fun, arr) {
        setLoader(true)
        console.log(allActivities)
        let newarr = [];

        for (let i = arr.length - 1; i >= 0; i--) {
            if (
                newarr.findIndex(
                    (x) =>
                        x.category == arr[i].category &&
                        x.assessment_section_name == arr[i].assessment_section_name &&
                        x.assessment_name == arr[i].assessment_name &&
                        x.created_at.split("-")[1] * 1 ==
                        arr[i].created_at.split("-")[1] * 1
                ) == -1
            ) {
                newarr.push(arr[i]);
            }
        }
        console.log(newarr)
        let obj = {};

        let datewise = [];
        newarr.forEach((res2) => {
            if (
                res2.created_at.split("-")[1] * 1 == fun.month &&
                res2.created_at.split("-")[0] * 1 == fun.year
            ) {
                datewise.push(res2);
            }
        });

        datewise.forEach((res) => {
            if (obj[`${res.category}${res.assessment_activity[0]}`] !== undefined) {
                obj[`${res.category}${res.assessment_activity[0]}`]++;
            } else {
                obj[`${res.category}${res.assessment_activity[0]}`] = 1;
            }
        });

        let arrlear = ["Practical", "Sensorial", "Language", "Maths", "Culture"];
        let arract = [...allActivities.current];

        let out = [];
        arract.forEach((res) => {
            let dd = [];
            arrlear.forEach((res2) => {
                dd.push(obj[`${res2}${res}`] !== undefined ? obj[`${res2}${res}`] : 0);
            });
            out.push(dd);
        });

        console.log(out)

        setTableColumns(out)
        setLoader(false)
    }

    const labels = [
        "M0", "M1", "M2", "M3", "M4", "M5", "M6", "M7", "M8", "M9", "M10",
        "M11", "M12", "M13", "M14", "M15", "M16", "M17", "M18", "M19", "M20",
        "M21", "M22", "M23", "M24", "M25", "M26", "M27", "M28", "M29", "M30",
        "M31", "M32", "M33", "M34", "M35", "M36", "M37", "M38", "M39", "M40",
        "M41", "M42", "M43", "M44", "M45",
    ];

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Base Line",
                backgroundColor: "#4472C4",
                borderColor: "#4472C4",
                data: [0, ...baseLine]
            },

            {
                label: "Child Progress",
                backgroundColor: "#ED7D31",
                borderColor: "#ED7D31",
                data: [0,
                    ...Object.values(childFiveYearProgress)
                ],
            },
        ],
    };



    const piedata1 = {
        labels: [
            "Independent",
            'Total',
        ],
        datasets: [{
            label: 'Practical',
            data: [
                pieChartData.childobj?.Practical,
                pieChartData.obj?.Practical - pieChartData.childobj?.Practical,
            ],
            backgroundColor: [
                "#FF914D",
                '#0097B2',
            ],
            hoverOffset: 4,
        }],
    };
    let options = {
        plugins: {
            tooltip: {
                enabled: false
            }
        }
    }
    const piedata2 = {
        labels: [
            "Independent",
            'Total',
        ],
        datasets: [{
            label: 'Sensorial',
            data: [
                pieChartData.childobj?.Sensorial,
                pieChartData.obj?.Sensorial - pieChartData.childobj?.Sensorial,
            ],
            backgroundColor: [
                "#FF914D",
                '#0097B2',
            ],
            hoverOffset: 4
        }]

    };
    const piedata3 = {
        labels: [
            "Independent",
            'Total',
        ],
        datasets: [{
            label: 'Language',
            data: [
                pieChartData.childobj?.Language,
                pieChartData.obj?.Language - pieChartData.childobj?.Language,
            ],
            backgroundColor: [
                "#FF914D",
                '#0097B2',
            ],
            hoverOffset: 4
        }]
    };
    const piedata4 = {
        labels: [
            "Independent",
            'Total',
        ],
        datasets: [{
            label: 'Culture',
            data: [
                pieChartData.childobj?.Culture,
                pieChartData.obj?.Culture - pieChartData.childobj?.Culture,
            ],
            backgroundColor: [
                "#FF914D",
                '#0097B2',
            ],
            hoverOffset: 4
        }]
    };
    const piedata5 = {
        labels: [
            "Independent",
            'Total',
        ],
        datasets: [{
            label: 'Maths',
            data: [
                pieChartData.childobj?.Maths,
                pieChartData.obj?.Maths - pieChartData.childobj?.Maths,
            ],
            backgroundColor: [
                "#FF914D",
                '#0097B2',
            ],
            hoverOffset: 4
        }]
    };



    return (
        <Sidebar>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {!loader ?
                <>
                    <Box className="ps-3">
                        <ArrowBackIos
                            sx={{ color: "#3FAEED", cursor: "pointer" }}
                            onClick={() => navigate('/childlisting')}
                        />
                    </Box>
                    <Box align="center">
                        <Box className="row my-5 justify-content-center" sx={{ width: '80%', m: 'auto' }}>
                            <div className="col-2">
                                <Pie data={piedata1} options={options} />
                                <div className="mt-3">Practical</div>
                            </div>
                            <div className="col-2">
                                <Pie data={piedata2} options={options} />
                                <div className="mt-3">Sensorial</div>
                            </div>
                            <div className="col-2">
                                <Pie data={piedata3} options={options} />
                                <div className="mt-3">Language</div>
                            </div>
                            <div className="col-2">
                                <Pie data={piedata4} options={options} />
                                <div className="mt-3">Culture</div>
                            </div>
                            <div className="col-2">
                                <Pie data={piedata5} options={options} />
                                <div className="mt-3">Maths</div>
                            </div>
                        </Box>

                        <Box sx={{ width: '80%', m: 'auto', height: '100vh' }}>
                            <Line data={data} />
                        </Box>

                        <Box align='right' sx={{ px: 4 }} className="d-flex align-items-center justify-content-between">
                            <Box>

                            </Box>
                            <Box>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        defaultValue={value}
                                        views={["year", "month"]}
                                        onChange={(e) => {
                                            // setDate({ year: e.$y, month: e.$M + 1 });
                                            refDate.current = { year: e.$y, month: e.$M + 1 };
                                            showGraph(
                                                { year: e.$y, month: e.$M + 1 },
                                                childData.current
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>

                        <CChart
                            style={{ width: "80%", height: "90vh" }}
                            type="bar"
                            data={{
                                labels: ["Practical", "Sensorial", "Language", "Maths", "Culture"],
                                datasets: tableColumns.map(((e, index) => {
                                    return {
                                        label: allActivities.current[index],
                                        backgroundColor: allColors[index],
                                        data: e,
                                    }
                                }))
                            }}
                            labels="months"
                        />







                    </Box ></> : null}
        </Sidebar >
    )
}