import { useEffect, useState } from "react";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Button,
  TextField,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Apis, baseURL } from "../../auth/GlobalUrl";

export const Login = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [loader, setLoader] = useState(false)


  async function checkPreviousLogin() {
    let tenant_id = localStorage.getItem("tenant_id");
    let email = localStorage.getItem("user_email")
    let id = localStorage.getItem("user_id");
    console.log({ tenant_id, email, id })
    if ((tenant_id && email && id) !== null) {

      try {
        setLoader(true)
        let res = await axios.post(Apis.checkPreviousLogin, { tenant_id, email, id })
        setLoader(false)
        if (res.data.data.length !== 0) {
          console.log(res.data.data)
          proceedLogin(res.data.data[0])
        }

      }
      catch (err) {
        setLoader(false)
        console.log(err)
      }

    }

  }

  useEffect(() => {


    checkPreviousLogin()

    // switch (role) {
    //   case "SuperAdmin":
    //     navigate("/adminlisting")
    //     break;
    //   case "Educator":
    //     if (
    //       educatorName !== null &&
    //       tenant_id !== null &&
    //       id !== null &&
    //       childcare_center !== null
    //     ) {
    //       console.log("edu edu edu");
    //       navigate(`/assessment/${id}`);
    //     }

    //     break;
    //   case "Admin":
    //     if (
    //       tenant_id !== null &&
    //       childcare_center !== null &&
    //       nav_name !== null
    //     ) {
    //       navigate("/home");
    //     }

    //     break;
    //   case "Parent":
    //     if (
    //       tenant_id !== null &&
    //       childcare_center !== null &&
    //       parentId !== null &&
    //       nav_name !== null
    //     ) {
    //       navigate(`/parent/${parentId}`);
    //     }
    //     break;

    //   default:
    //     break;
    // }

  }, []);

  const loginSubmit = async () => {
    try {
      let data = {
        email: user,
        password: pass,
      };
      let res = await axios.post(`${baseURL}login`, data)
      console.log(res.data.data)
      proceedLogin(res.data.data)
      setLoader(false)

    }
    catch (err) {
      console.log(err)
      setLoader(false)
      setShowAlert(true);
    }
  }

  async function proceedLogin(userData) {
    setLoader(false)
    console.log(userData)

    localStorage.setItem("role", userData.role);
    console.log(userData._id)
    localStorage.setItem("user_id", userData._id);

    switch (userData.role) {
      case 'SuperAdmin':
        localStorage.setItem("nav_name", "Admin");
        localStorage.setItem("logo_url", "");
        navigate(`/adminlisting`);
        break;
      case "Admin":
        localStorage.setItem("nav_name", "Home");
        localStorage.setItem('roleName', userData.admin_name)
        localStorage.setItem('teacher_name', "Admin")
        localStorage.setItem("user_email", userData.email);

        // localStorage.setItem("tenant_id", userData.tenant_id);
        // localStorage.setItem("childcare_center", userData.child_care_center);
        // getLogo(userData, "/home");
        navigate("/home")
        break;

      case 'Parent':
        localStorage.setItem("parentId", userData._id);
        localStorage.setItem("nav_name", "Observation");
        localStorage.setItem("user_email", userData.parent_email);
        localStorage.setItem("tenant_id", userData.tenant_id);
        // localStorage.setItem(
        //   "childcare_center",
        //   userData.child_care_center
        // );
        console.log(userData._id)
        getLogo(userData, `/parent/${userData._id}`);
        break;
      case "Room":
        // localStorage.setItem("nav_name", "Configuration");
        localStorage.setItem("roomName", userData.room_name);
        localStorage.setItem("roomId", userData._id);
        localStorage.setItem("user_email", userData.room_email);
        localStorage.setItem("tenant_id", userData.tenant_id);
        navigate(`/allEducatorsForRoom`);
        break;
      default:
        break;
    }

    console.log(userData)
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: user,
      password: pass,
    };
    setLoader(true)
    axios
      .post(`${baseURL}login`, data)
      .then(async (res) => {
        console.log(res.data.data)

        proceedLogin(res.data.data)

        return
        setLoader(false)
        console.log(res.data.data);
        console.log(res.data.data.child_care_center);

        if (res.data.data.role === "SuperAdmin") {
          localStorage.setItem("role", res.data.data.role);
          localStorage.setItem("nav_name", "Admin");


          // localStorage.setItem("isLoggedIn", "true");

          // navigate(`/adminlisting`);
          return
        }
        return
        // else if (res.data.data.role === "Room") {
        if (res.data.data.role === "Room") {
          console.log(res.data.data)
          localStorage.setItem("nav_name", "Configuration");

          localStorage.setItem("role", res.data.data.role);
          // localStorage.setItem("educatorName", res.data.data.room_name);
          localStorage.setItem("roomName", res.data.data.room_name);
          // localStorage.setItem('roleName', res.data.data.room_name)
          localStorage.setItem("tenant_id", res.data.data.tenant_id);
          // localStorage.setItem(
          //   "childcare_center",
          //   res.data.data.child_care_center
          // );
          // localStorage.setItem("id", res.data.data._id);

          // getLogo(res.data.data, `/verifyPin`);

          //  localStorage.setItem("isLoggedIn", "true");
        } else if (res.data.data.role === "Parent") {
          console.log(res.data.data);
          localStorage.setItem("role", res.data.data.role);
          localStorage.setItem("parentId", res.data.data._id);
          localStorage.setItem('roleName', res.data.data.parent_name)
          localStorage.setItem("nav_name", "Observation");
          //  localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("tenant_id", res.data.data.tenant_id);
          localStorage.setItem(
            "childcare_center",
            res.data.data.child_care_center
          );
          getLogo(res.data.data, `/parent/${res.data.data._id}`);

        } else if (res.data.data.role === "Admin") {
          console.log(res.data.data);
          localStorage.setItem("role", res.data.data.role);
          localStorage.setItem("nav_name", "Home");
          localStorage.setItem('roleName', res.data.data.admin_name)
          //  localStorage.setItem("isLoggedIn", "true");
          localStorage.setItem("tenant_id", res.data.data.tenant_id);
          localStorage.setItem(
            "childcare_center",
            res.data.data.child_care_center
          );
          getLogo(res.data.data, "/home");
        }
      })
      .catch((error) => {
        setLoader(false)
        setShowAlert(true);
      });
    // if (user === "admin@gmail.com" && pass === "admin@1234") {
    //   navigate("/home");
    // } else {
    //   setShowAlert(true);
    // }
  };
  function getLogo(response, path) {
    // console.log(res);
    // let url = `${baseURL}logo/getLogo/${response.tenant_id}`;
    // axios
    //   .get(url)
    //   .then((res) => {
    //     if (res.data) {
    //       if (res.data.data == null) {
    //         localStorage.setItem("logo_url", "");
    //       } else {
    //         localStorage.setItem("logo_url", res.data.data.logo_url);
    //       }
    //     }
    navigate(path);

    //   console.log(res);
    // })
    // .catch((err) => console.log(err));
  }

  return (
    <section className={style.main_sec}>
      <div className={style.left}>
        <img src="/images/childcare.jpg" alt="eee" className=" h-100 " />
      </div>
      <div className={style.right}>
        <div className={style.inner}>
          <img className="img-style" src="/images/logo.png" alt="Logo" />
          <br />
          {showAlert ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              Incorrect Username or Password
            </Alert>
          ) : (
            <></>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              // label="Username"
              placeholder="Enter Your Username"
              type="email"
              fullWidth
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
            <Typography
              sx={{ fontSize: "14px", mb: 2, color: "gray" }}
            ></Typography>
            <FormControl sx={{ mb: 2 }} fullWidth>
              {/* <InputLabel>Password</InputLabel> */}
              <OutlinedInput
                // label="Password"
                placeholder="Enter Your Password"
                type={showPass ? "text" : "password"}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <small style={{ color: "gray" }}></small>
            </FormControl>
            <Typography align="right" sx={{ color: "#1799cd" }} my={2}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/forgotpassword")}
              >
                Forgot password ?
              </span>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ background: "black", color: "white" }}
              type="submit"
              fullWidth
            >
              LOGIN
            </Button>
          </form>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </section>
  );
};
