import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../baseURL";
import { useMediaQuery } from "@mui/material";
import { SnackBar } from "../SnackBar";

export const CreateCredential = () => {
  let { id } = useParams();
  let [confirmError, setConfirmError] = useState(false)
  let token = id.split("$0&")[0];
  let role = id.split("$0&")[1];
  let userId = id.split("$0&")[2];
  const [loader, setLoader] = useState(false);
  const matches = useMediaQuery("(min-width:800px)");
  let navigate = useNavigate();

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });
  const [userPassword, setUserPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [regexError, setRegexError] = useState(false);

  useEffect(() => {
    console.log(role);
    console.log(userId);
    console.log(token);
    if (role == undefined || userId == undefined || token == undefined) {
      navigate("/");
    }
  }, []);

  function formSubmit(e) {
    e.preventDefault();
    setRegexError(false);
    setConfirmError(false)
    if (userPassword.password.trim() == userPassword.confirmPassword.trim()) {
      let passwordregex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~"]{8,16}$/;

      if (userPassword.password.match(passwordregex) !== null) {
        setLoader(true);
        let url = `${baseURL}verifyAndAddCred`;
        axios
          .post(url, {
            token,
            id: userId,
            role,
            password: userPassword.password.trim(),
          })
          .then((res) => {
            console.log(res);
            setLoader(false);
            navigate("/");
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
            ShowSnackbar({
              show: true,
              vertical: "top",
              horizontal: "right",
              msg: 'User not found',
              type: "error",
            });
          });
      } else {
        setRegexError(true);
      }
    } else {
      setConfirmError(true)
    }
  }
  return (
    <>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ height: "100vh", bgcolor: "#EFF6FF" }}>
        <Box
          sx={{ width: "100%", height: "80vh" }}
          className="d-sm-flex flex-column justify-content-center align-items-center"
        >
          <Typography variant="h4" mb={3} align="center">
            Create new password
          </Typography>

          <form onSubmit={formSubmit}>
            <Box
              className="px-5 mx-2"
              sx={{
                width: matches ? "740px" : "100%",
                boxShadow: "3px 3px 3px grey",
                p: 4,
                pb: 10,
                bgcolor: "white",
              }}
            >
              <label>New Password:</label>
              <br />
              <TextField
                onChange={(e) =>
                  setUserPassword({ ...userPassword, password: e.target.value })
                }
                value={userPassword.password}
                required
                fullWidth
                sx={{ mt: 2 }}
              />
              <label className="mt-3">Confirm Password:</label>
              <br />
              <TextField
                onChange={(e) =>
                  setUserPassword({
                    ...userPassword,
                    confirmPassword: e.target.value,
                  })
                }
                value={userPassword.confirmPassword}
                required
                fullWidth
                sx={{ mt: 2 }}
              />
              {regexError ?
                <div style={{ color: 'red' }}>

                  Minimum length of password should be 8 character Password must
                  contain: At least one UPPERCASE, one lowercase, one number, one
                  special character(!,@,#,$)
                </div> : null}
              {confirmError ?
                <div style={{ color: 'red' }}>Password doesn't  match</div> : null
              }
              <Box align="center" mt={4}>
                <Button type="submit" className="btn_primary">
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
