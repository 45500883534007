import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { CheckRoleParent } from "./auth/CheckRoleParent";
import { CheckRoleAdmin } from "./auth/CheckRoleAdmin";
import { Home, Login, NotFound } from "./pages";
import "./style.css";
import { Configuration } from "./pages/Configuration/Configuration";
import { EditConfiguration } from "./pages/Configuration/EditConfiguration";
import { Routines } from "./pages/Routines/Routines";
import { ChildAssesment } from "./pages/ChildAssesment/ChildAssesment";
import { AddRecord } from "./pages/AddRecord/AddRecord";
import Accident from "./pages/Accident/Accident";
import Injury from "./pages/Injury/Injury";
import InjurySummary from "./pages/InjurySummary/InjurySummary";
import InjuryonIntake from "./pages/InjuryonIntake/InjuryonIntake";
import IntakeSummary from "./pages/IntakeSummary/IntakeSummary";
import Illness from "./pages/Illness/Illness";
import { AddConfigurationHome } from "./pages/Configuration/AddConfigurationHome";
import Educators from "./pages/Educators/Educators";
import TeacherDetails from "./pages/TeacherDetails/TeacherDetails";
import { AllParentChild } from "./pages/Parent/AllParentChild";
import AddChild from "./pages/AddChild/AddChild";
import ParentDetails from "./pages/ParentDetails/ParentDetails";
import AddParent from "./pages/AddParent/AddParent";
import ChildListing from "./pages/childListing/ChildListing";
import { Assessment } from "./pages/Assessment/Assessment";
import Childview from "./pages/ChildView/ChildView";
import Parentview from "./pages/ParentDetails/ParentView";
import Teacherview from "./pages/TeacherView/TeacherView";
import Starter from "./pages/Starter/Starter";
import ChildRecords from "./pages/ChildRecords/ChildRecords";
import { CheckRoleSuperAdmin } from "./auth/CheckRoleSuperAdmin";
import { AdminListing } from "./pages/SuperAdmin/AdminListing";
import { ViewAdmin } from "./pages/SuperAdmin/ViewAdmin";
import { EditAdmin } from "./pages/SuperAdmin/EditAdmin";
import { AddAdmin } from "./pages/SuperAdmin/AddAdmin";
import { tenant_id } from "./Context";
import Rooms from "./pages/Rooms/Rooms";
import { createContext, useEffect, useState } from "react";
import EducatorListing from "./pages/Rooms/EducatorListing";
import RChildListing from "./pages/Rooms/RChildListing";
import RoomView from "./pages/Rooms/RoomView";
import AssignRoom from "./pages/assignRoom/AssignRoom";
import RemoveAssignRoom from "./pages/assignRoom/RemoveAssignRoom";
import { AllChildOfRoom } from "./pages/Assessment/AllChildOfRoom";
import { AssignRoomToChild } from "./pages/childListing/AssignRoomToChild";
import EditParent from "./pages/ParentDetails/EditParent";
import EditChild from "./pages/EditChild/EditChild";
import EditEducator from "./pages/Educators/EditEducator";
import { AllChildForRoutines } from "./pages/Routines/AllChildForRoutines";
import { EditAllConfiguration } from "./pages/Configuration/EditAllConfiguration";
import { AdminRecord } from "./pages/Record/AdminRecord";
import { CreateCredential } from "./pages/CreateCredential/CreateCredential";
import { ForgotPassword } from "./pages/CreateCredential/ForgotPassword";
import { ChildGraph } from "./pages/childListing/ChildGraph";
import { EducatorPinAuth } from "./auth/EducatorPinAuth";
import { EducatorPin } from "./auth/EducatorPin";
import { store } from "./Redux-toolkit/Store";
import { Provider, useDispatch } from 'react-redux'
import { TermsGoals } from "./pages/Assessment/TermsGoals";
import { CheckRoomAuth } from "./auth/CheckRoomAuth";
import { AllEducatorsForRoom } from "./pages/AllEducatorsForRoom/AllEductorsForRoom";
import { AddBaseLine } from "./pages/BaseLine/AddBaseLine";
import { ChildcareListing } from "./pages/SuperAdmin/ChildcareCenter/ChildcareListing";
import { AddCenter } from "./pages/SuperAdmin/ChildcareCenter/AddCenter";
import { EditCenter } from "./pages/SuperAdmin/ChildcareCenter/EditCenter";

export const contextData = createContext()

export const Router = () => {

  let [snackBarState, setSnackbarState] = useState(false)
  let [navState, setnavState] = useState('')

  let dispatch = useDispatch()

  let navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("role") == 'Admin') {

      setnavState('Home')
    }
    else if (localStorage.getItem("role") == 'Parent') {
      setnavState('Parent')
    }
    else if (localStorage.getItem("role") == 'Educator') {
      setnavState('Assessment')
    }
    else {
      setnavState('Admin')
    }



    // if (localStorage.getItem("role") !== "SuperAdmin") {
    //   localStorage.getItem('tenant_id') == null ? navigate("/") : null;
    //   localStorage.setItem("isLoggedIn", false);
    // }
  }, []);

  return (
    <>

      <contextData.Provider value={{ state: snackBarState, setState: setSnackbarState, setNav: setnavState, nav: navState }}>
        <CssBaseline />
        <Routes>
          {/* <Route element={<LoginKeeper />}> */}
          <Route path='/' element={<Login />} />
          {/* </Route> */}

          //Educator routes

          <Route element={<CheckRoomAuth />}>

            <Route path="allEducatorsForRoom" element={<AllEducatorsForRoom />} />
            <Route path="verifyPin/:id" element={<EducatorPin />} />

            <Route element={<EducatorPinAuth />}>
              <Route path="routines" element={<Routines />} />
              <Route path="assessment/:id" element={<Assessment />} />
              <Route path="accident" element={<Accident />} />
              <Route path="addRecord/:id" element={<AddRecord />} />
              <Route path="childAssesment/:id" element={<ChildAssesment />} />
              <Route path="injury" element={<Injury />} />
              <Route path="injurysummary" element={<InjurySummary />} />
              <Route path="injuryonintake" element={<InjuryonIntake />} />
              <Route path="intakesummary" element={<IntakeSummary />} />
              <Route path="illness" element={<Illness />} />
              <Route path="allchildofroom/:id" element={<AllChildOfRoom />} />
              <Route path="allchildforroutine/:id" element={<AllChildForRoutines />} />
              <Route path="allRecord/:id" element={<Starter />} />
              <Route path="termsGoals/:id" element={<TermsGoals />} />
            </Route>

          </Route>

          {/* </Route> */}

          //Parent Routes
          <Route element={<CheckRoleParent />}>
            <Route path="parent/:id" element={<AllParentChild />} />
            <Route path="childrecords" element={<ChildRecords />} />
          </Route>
          //Admin Routes
          <Route element={<CheckRoleAdmin />}>
            <Route path="home" element={<Home />} />
            <Route path="configuration" element={<Configuration />} />

            <Route path="termsGoalsByAdmin/:id" element={<TermsGoals />} />

            <Route path="editConfiguration/:id" element={<EditConfiguration />} />
            <Route path="addconfiguration" element={<AddConfigurationHome />} />
            <Route path="educators" element={<Educators />} />
            <Route path="teacherdetails" element={<TeacherDetails />} />
            <Route path="addchild/:id/" element={<AddChild />} />
            <Route path="parentdetails" element={<ParentDetails />} />
            <Route path="addparent" element={<AddParent />} />
            <Route path="childlisting" element={<ChildListing />} />
            <Route path="assignroom/:id" element={<AssignRoom />} />
            <Route path="childview/:id" element={<Childview />} />
            <Route path="parentview/:id" element={<Parentview />} />
            <Route path="teacherview/:id" element={<Teacherview />} />
            <Route path="editparent/:id" element={<EditParent />} />
            <Route path="editchild/:id" element={<EditChild />} />
            <Route path="starter" element={<Starter />} />
            <Route path="rooms" element={<Rooms />} />
            <Route path="roomview/:id" element={<RoomView />} />
            <Route path="editeducator/:id" element={<EditEducator />} />
            <Route path="educatorlisting" element={<EducatorListing />} />
            <Route path="rchildlisting" element={<RChildListing />} />
            <Route path="editallconfiguration/:id" element={<EditAllConfiguration />} />
            <Route path="childGraph/:id" element={<ChildGraph />} />
            <Route path="removeassignroom/:id" element={<RemoveAssignRoom />} />
            <Route path="childAssesmentbyadmin/:id" element={<ChildAssesment />} />
            <Route path="assignroomtochild/:id" element={<AssignRoomToChild />} />
            <Route path="records" element={<AdminRecord />} />
            <Route path="addBaseLine" element={<AddBaseLine />} />
            <Route path="*" element={<NotFound />} />

          </Route>
          // Super Admin Routes
          <Route element={<CheckRoleSuperAdmin />}>
            <Route path="adminlisting" element={<AdminListing />} />
            <Route path="addadmin" element={<AddAdmin />} />
            <Route path="editadmin/:id" element={<EditAdmin />} />
            <Route path="viewadmin/:id" element={<ViewAdmin />} />
            <Route path="AllCenters" element={<ChildcareListing />} />
            <Route path="AddCenter" element={<AddCenter />} />
            <Route path="EditCenter/:id" element={<EditCenter />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="CreateCredential/:id" element={<CreateCredential />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </contextData.Provider>


    </>
  );
};
