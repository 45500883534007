import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { ParentLogoApis } from "../Redux-toolkit/Slices/ParentLogoSlices";

export const CheckRoleParent = () => {
  const login = useRef(false);
  const role = localStorage.getItem("role");
  const tenant_id = localStorage.getItem("tenant_id");
  const childcare_center = localStorage.getItem("childcare_center");
  const parentId = localStorage.getItem("parentId");
  let dispatch = useDispatch()
  useEffect(() => {
    // this  action is use to fetch latest logo of curent parent 
    dispatch(ParentLogoApis())
  }, [])

  if (role === "Parent" && tenant_id !== null && childcare_center !== null && parentId !== null) {
    login.current = true;
  }
  else {
    login.current = false
  }

  return login.current ? <Outlet /> : <Navigate to="/" replace />;
};
