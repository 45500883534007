import React, { createRef, useEffect, useRef } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { SnackBar } from "../SnackBar";
import { baseURL } from "../baseURL";
import axios from "axios";
import { Close } from "@mui/icons-material";

export const AdminRecord = () => {
  let [newRecordName, setNewRecordName] = useState("");
  let [newSubRecordName, setNewSubRecordName] = useState("");
  let [newSubActivityName, setNewSubActivityName] = useState("");
  const [loader, setLoader] = useState(true);
  const [addRecordId, setAddRecordId] = useState("");
  const [deleteMode, setDeleteMode] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [allRecords, setAllRecords] = useState([]);
  const recordAdd = useRef(false);
  const [recordIndex, setRecordIndex] = useState(0);

  const [subRecords, setSubRecords] = useState([]);
  const [subActivity, setSubActivity] = useState([]);

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNewRecordName("");
  };
  let refref = createRef(null);
  let tenant_id = localStorage.getItem("tenant_id");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
    setNewSubRecordName("");
  };
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
    setNewSubActivityName("");
  };
  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "simple-popover" : undefined;

  function getAllRecords() {
    let url = `${baseURL}record/get/allRecord/${tenant_id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length !== 0) {
          setAllRecords(res.data.data);
          setAddRecordId(res.data.data[0]._id);
          setSubRecords(res.data.data[0].sub_record);
          setSubActivity(res.data.data[0].record_activity);

          if (recordAdd.current) {
            setTimeout(() => {
              let all = document.querySelectorAll(".adminRecord");
              console.log(all);
              for (let i of all) {
                i.style.color = "#fa981d";
                i.style.backgroundColor = "#ffeed8";
              }

              all[all.length - 1].style.color = "white";
              all[all.length - 1].style.backgroundColor = "#fa981d";
              setRecordIndex(res.data.data.length - 1);
              setAddRecordId(res.data.data[res.data.data.length - 1]._id);
              setSubRecords(res.data.data[res.data.data.length - 1].sub_record);
              setSubActivity(
                res.data.data[res.data.data.length - 1].record_activity
              );
            }, 100);
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addRecordName(e) {
    e.preventDefault();
    let num = 0;
    allRecords.forEach((res) => {
      if (
        res.record_name.trim().toLowerCase() ==
        newRecordName.trim().toLowerCase()
      ) {
        num++;
      }
    });
    if (num == 0) {
      handleClose();
      setLoader(true);
      let url = `${baseURL}record/addRecord`;
      axios
        .post(url, { record_name: newRecordName, tenant_id })
        .then((res) => {
          console.log(res.data.data);
          setNewRecordName("");
          setLoader(false);
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: "Record added successfully",
            type: "success",
          });
          recordAdd.current = true;
          getAllRecords();
        })
        .catch((err) => console.log(err));
    } else {
      console.log("else ese");
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Record already exist",
        type: "error",
      });
    }
  }
  useEffect(() => {
    getAllRecords();
  }, []);

  function selectRecord(e, res, index) {
    setRecordIndex(index);
    let all = document.querySelectorAll(".adminRecord");
    all.forEach((res) => {
      res.style.color = "#fa981d";
      res.style.backgroundColor = "#ffeed8";
    });
    e.target.style.color = "white";
    e.target.style.backgroundColor = "#fa981d";
    setSubRecords(res.sub_record);
    setSubActivity(res.record_activity);
    setAddRecordId(res._id);
  }

  function addSubRecordSubmit(e) {
    e.preventDefault();

    let arr = subRecords.map((e) => e.toLowerCase().trim());

    if (arr.indexOf(newSubRecordName.trim().toLowerCase()) == -1) {
      setLoader(true);
      handleClose2();
      let url = `${baseURL}record/update/${addRecordId}`;
      axios
        .put(url, { sub_record: [...subRecords, newSubRecordName] })
        .then((res) => {
          console.log(res);
          setSubRecords([...subRecords, newSubRecordName]);
          let arr = [...allRecords];
          arr[recordIndex].sub_record.push(newSubRecordName);
          setAllRecords(arr);
          console.log(arr);
          setLoader(false);
          setNewSubRecordName("");
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: "Sub Record added successfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Sub Record already exist",
        type: "error",
      });
    }
  }
  function addSubActivitySubmit(e) {
    e.preventDefault();
    let rr = subActivity.map((e) => e.trim().toLowerCase());
    if (rr.indexOf(newSubActivityName.trim().toLowerCase()) == -1) {
      setLoader(true);
      handleClose3();
      let url = `${baseURL}record/update/${addRecordId}`;
      axios
        .put(url, {
          record_activity: [...subActivity, newSubActivityName],
        })
        .then((res) => {
          setSubActivity([...subActivity, newSubActivityName]);
          let arr = [...allRecords];
          arr[recordIndex].record_activity.push(newSubActivityName);
          setAllRecords(arr);
          console.log(res);
          setLoader(false);
          setNewSubActivityName("");
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: "Record activity successfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Sub activity already exist",
        type: "error",
      });
    }
  }
  function deleteRecord(res, index) {
    console.log(res);
    console.log(index);
    setLoader(true);
    let url = `${baseURL}record/deleteRecord/${res._id}`;

    axios
      .delete(url)
      .then((res2) => {
        console.log(res2);
        let arr = [...allRecords];
        arr.splice(index, 1);
        setAllRecords(arr);
        console.log(arr);
        if (arr.length !== 0) {
          setSubRecords(arr[0].sub_record);
          setSubActivity(arr[0].record_activity);
          let all = document.querySelectorAll(".adminRecord");

          all.forEach((ee, index) => {
            if (index == 0) {
              all[index].style.color = "white";
              all[index].style.backgroundColor = "#fa981d";
            } else {
              all[index].style.color = "#fa981d";
              all[index].style.backgroundColor = "#ffeed8";
            }
          });

          setRecordIndex(0);
          setAddRecordId(arr[0]._id);
          console.log(all[0]);
          setLoader(false);
        } else {
          setSubActivity([]);
          setSubRecords([]);
          setDeleteMode(false);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <Sidebar>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className="d-sm-flex" mx={2}>
        <Box className="w-md-50 w-100" sx={{ p: 2 }}>
          <Box
            sx={{ bgcolor: "white", py: 2, boxShadow: "0px 0px 9px grey" }}
            className="recordCon"
          >
            <Box className="fs-4" sx={{ width: "100%" }} align="center">
              Record
            </Box>
            {allRecords.length !== 0 ? (
              <Button
                sx={{ textTransform: "capitalize" }}
                className="btn_primary ms-3"
                onClick={() => setDeleteMode(!deleteMode)}
              >
                {" "}
                {deleteMode ? "Cancel" : "Delete Record"}
              </Button>
            ) : null}
            <Grid container>
              {allRecords.map((res, index) => {
                return (
                  <Grid
                    sx={{
                      boxShadow: deleteMode ? "2px 2px 8px black" : "none",
                    }}
                    onClick={(e) => {
                      !deleteMode ? selectRecord(e, res, index) : null;
                    }}
                    item
                    className={`dd adminRecord ${
                      index == 0 ? "activeRecord" : ""
                    }`}
                    key={index}
                  >
                    {res.record_name}
                    {deleteMode ? (
                      <span
                        className="cross"
                        onClick={() => deleteRecord(res, index)}
                      >
                        x
                      </span>
                    ) : null}
                  </Grid>
                );
              })}

              <Grid
                item
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                className="dd"
              >
                +
              </Grid>
            </Grid>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ p: 2 }}>
              <form onSubmit={addRecordName}>
                <TextField
                  onChange={(e) =>
                    e.target.value == " "
                      ? (e.target.value = "")
                      : setNewRecordName(e.target.value)
                  }
                  required
                  value={newRecordName}
                />
                <Grid sx={{ mt: 2 }} align="right">
                  <Button
                    className="cancel_btn"
                    sx={{ mx: 1 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button className="btn_primary" type="submit">
                    Save
                  </Button>
                </Grid>
              </form>
            </Box>
          </Popover>
        </Box>

        <Box className="w-sm-50 w-100" sx={{ height: "300px", p: 2 }}>
          <Box
            sx={{ bgcolor: "white", py: 2, boxShadow: "0px 0px 9px grey" }}
            className="recordCon"
          >
            <Box className="fs-4" sx={{ width: "100%" }} align="center">
              Sub Record
            </Box>
            <Grid container>
              {subRecords.map((res, index) => {
                return (
                  <Grid item className="subRecord" key={index}>
                    {res}
                  </Grid>
                );
              })}
              {allRecords.length !== 0 ? (
                <Grid
                  item
                  aria-describedby={id2}
                  variant="contained"
                  onClick={handleClick2}
                  className="subRecord"
                >
                  +
                </Grid>
              ) : null}
            </Grid>
            <Popover
              id={id2}
              open={open2}
              anchorEl={anchorEl2}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ p: 2 }}>
                <form onSubmit={addSubRecordSubmit}>
                  <TextField
                    onChange={(e) =>
                      e.target.value == " "
                        ? (e.target.value = "")
                        : setNewSubRecordName(e.target.value)
                    }
                    required
                    value={newSubRecordName}
                  />
                  <Grid sx={{ mt: 2 }} align="right">
                    <Button
                      className="cancel_btn"
                      sx={{ mx: 1 }}
                      onClick={handleClose2}
                    >
                      Cancel
                    </Button>
                    <Button className="btn_primary" type="submit">
                      Save
                    </Button>
                  </Grid>
                </form>
              </Box>
            </Popover>
            <Grid container>
              {subActivity.map((res, index) => {
                return (
                  <Grid item className="subActivity" key={index}>
                    {res}
                  </Grid>
                );
              })}
              {subRecords.length !== 0 ? (
                <Grid
                  item
                  aria-describedby={id3}
                  variant="contained"
                  onClick={handleClick3}
                  className="subActivity"
                >
                  +
                </Grid>
              ) : null}
            </Grid>

            <Popover
              id={id3}
              open={open3}
              anchorEl={anchorEl3}
              onClose={handleClose3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ p: 2 }}>
                <form onSubmit={addSubActivitySubmit}>
                  <TextField
                    onChange={(e) =>
                      e.target.value == " "
                        ? (e.target.value = "")
                        : setNewSubActivityName(e.target.value)
                    }
                    required
                    value={newSubActivityName}
                  />
                  <Grid sx={{ mt: 2 }} align="right">
                    <Button
                      className="cancel_btn"
                      sx={{ mx: 1 }}
                      onClick={handleClose3}
                    >
                      Cancel
                    </Button>
                    <Button className="btn_primary" type="submit">
                      Save
                    </Button>
                  </Grid>
                </form>
              </Box>
            </Popover>
          </Box>
        </Box>
      </Box>
    </Sidebar>
  );
};
