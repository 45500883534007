import {
  Add,
  AddCircle,
  Close,
  Delete,
  DisabledByDefault,
  OndemandVideo,
  Photo,
  Upload,
} from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import "../../style.css";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import axios from "axios";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import storage from "../../firebase/firebase";
import { SnackBar } from "../SnackBar";
import { Modal } from "react-bootstrap";
import { AddGoal } from "./AddGoal";
import { AddSubsectionIntoSection } from "./AddSubsectionIntoSection";

export const ConfigurationCommonComp = ({
  allMaterial,
  configData,
  functions,
  mode,
  allData,
  index,
  latestData,
  allGoals,

}) => {
  let tenant_id = localStorage.getItem("tenant_id");
  let subsectionref = React.useRef(null)
  let [sectionLoader, setSectionLoader] = useState(false);
  let [sec_name_dialog, setSec_name_dialog] = useState(false);
  let [subSec_name_dialog, setSubsec_name_dialog] = useState(false);
  let [sectionName, setSectionName] = useState("");
  let [subSectionName, setSubSectionName] = useState("");
  let [currentSection, setCurrentSection] = useState(null)

  const [showm, setShowm] = useState(false);

  const handleClosem = () => setShowm(false);
  const handleShowm = () => setShowm(true);

  React.useEffect(() => {
    if (mode == "edit" || mode == "allEdit") {

      if (latestData.current[index].section_name !== '') {

        let ind = allMaterial.allSections.findIndex(r => r.section_name == latestData.current[index].section_name)
        console.log(ind)
        if (ind !== -1) {
          setCurrentSection(allMaterial.allSections[ind])
          getAllSubsection(allMaterial.allSections[ind]._id)
        }
      }
    }

  }, [])

  let [openLoader, setOpenLoader] = useState(false);

  const [firstDialog, setFirstDialog] = useState(false);
  const [secondDialog, setSeconddialog] = useState(false);

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(true);
  const [newAge, setNewAge] = useState("");
  const [newActivity, setNewActivity] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [embedUrl, setEmbedUrl] = useState("");
  const [allSubsectionData, setAllsubsectionData] = useState([])

  function addAssementActivity(e) {
    e.preventDefault();
    setSeconddialog(true);
  }

  async function getAllSubsection(secId) {
    try {
      console.log(secId)
      let res = await axios.get(Apis.ASSESSMENT.getAllSubsectionBySectionId + secId)
      console.log(res.data.data)
      setAllsubsectionData(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  function newfun() {
    getAllSubsection(currentSection._id)

  }

  function addSectionName(e) {
    // setOpenLoader(true);
    setSectionLoader(true);
    e.preventDefault();
    // console.log(allSections);
    let num = 0;
    allMaterial.allSections.forEach((res) => {
      if (
        res.section_name.trim().toLowerCase() ==
        sectionName.trim().toLowerCase()
      ) {
        num++;
      }
    });
    if (num == 0) {
      axios
        .post(`${baseURL}section/addSection`, {
          section_name: sectionName.trim().toLowerCase(),
          tenant_id,
        })
        .then((res) => {
          console.log(res);
          setSec_name_dialog(false);
          setSectionLoader(false);
          functions.getAllMaterial();
          ShowSnackbar({
            ...snackbar,
            show: true,
            msg: "Section Name added successfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.errormessage) {
            alert(err.response.data.errormessage);
          }
        });
    } else {
      setSectionLoader(false);
      ShowSnackbar({
        ...snackbar,
        show: true,
        msg: "Section Name is already exist",
        type: "error",
      });
    }
  }
  function addSubSectionName(e) {
    e.preventDefault();
    let num = 0;
    allMaterial.allSubSections.forEach((res) => {
      if (
        res.subsection_name.trim().toLowerCase() ==
        subSectionName.trim().toLowerCase()
      ) {
        num++;
      }
    });
    if (num == 0) {
      setSectionLoader(true);
      setOpenLoader(true);
      axios
        .post(`${baseURL}subsection/addsubSection`, {
          subsection_name: subSectionName.trim().toLowerCase(),
          tenant_id,
        })
        .then((res) => {
          console.log(res);
          //   setSectionLoader(false);
          functions.getAllMaterial();
          setSubsec_name_dialog(false);
          ShowSnackbar({
            ...snackbar,
            show: true,
            msg: "Subsection added sucessfully",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.errormessage) {
            alert(err.response.data.errormessage);
          }
        });
    } else {
      setSectionLoader(false);
      ShowSnackbar({
        ...snackbar,
        show: true,
        msg: "Sub section Name is already exist",
        type: "error",
      });
    }
  }
  React.useEffect(() => {
    if (mode == "allEdit" || mode == "edit") {
      setFirstDialog(true);
      setSeconddialog(true);
    }
  }, []);

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const id = open ? "simple-popover" : undefined;
  const id2 = open2 ? "simple-popover2" : undefined;

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  function addActivity(e) {
    e.preventDefault();
    console.log("add activity");

    let num = 0;

    allMaterial.allActivities.map((ag) => {
      if (
        ag.activity_name.toLowerCase().trim() ==
        newActivity.toLowerCase().trim()
      ) {
        num++;
      }
    });
    if (num == 0) {
      axios
        .post(`${baseURL}activity/addActivity`, {
          activity_name: newActivity.trim().toLowerCase(),
          tenant_id,
        })
        .then((res) => {
          console.log(res);
          handleClose2();
          handleClose();

          functions.getAllMaterial();

          ShowSnackbar({
            ...snackbar,
            show: true,
            msg: "activity added sucessfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ShowSnackbar({
        ...snackbar,
        show: true,
        msg: "activity already exist",
        type: "warning",
      });
    }
  }

  function addAge(e) {
    e.preventDefault();
    setOpenLoader(true);
    let num = 0;

    allMaterial.allAges.map((ag) => {
      if (ag.age == newAge) {
        num++;
      }
    });
    if (num == 0) {
      console.log("date added added");

      axios
        .post(`${baseURL}age/addAge`, { age: newAge, tenant_id })
        .then((res) => {
          console.log(res);
          ShowSnackbar({
            ...snackbar,
            show: true,
            msg: "Age added successfully",
            type: "success",
          });
          functions.getAllMaterial();
        })
        .catch((err) => {
          console.log(err);
        });
      setNewAge("");
      handleClose();
    } else {
      ShowSnackbar({
        ...snackbar,
        show: true,
        msg: "Age already exist",
        type: "error",
      });
    }
  }

  function activitycheckbox(e, res) {
    console.log(e.target.checked);
    console.log(res);
    // let arr = [...allData.state];
    if (e.target.checked) {
      latestData.current[index].assesmentActivity.push(res);
    } else {
      latestData.current[index].assesmentActivity.splice(
        latestData.current[index].assesmentActivity.indexOf(res),
        1
      );
    }
    // allData.setState(arr);
  }
  function agegroupcheckbox(e, res) {
    // let arr = [...allData.state];
    if (e.target.checked) {
      latestData.current[index].ageGroup.push(res);
    } else {
      latestData.current[index].ageGroup.splice(
        latestData.current[index].ageGroup.indexOf(res),
        1
      );
    }
    // allData.setState(arr);
  }

  function inpChange(e, ind) {
    let image = e.target.files[0];

    if (
      image.name.split(".")[image.name.split(".").length - 1].toLowerCase() ==
      "jpg" ||
      image.name.split(".")[image.name.split(".").length - 1].toLowerCase() ==
      "png" ||
      image.name.split(".")[image.name.split(".").length - 1].toLowerCase() ==
      "pdf" ||
      image.name.split(".")[image.name.split(".").length - 1].toLowerCase() ==
      "mp4"
    ) {
      console.log(image, "kok0k00o0o0oo");

      const storageRef = ref(storage, `/images/${Math.random()}${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      setLoader(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (err) => {
          setLoader2(false);
          console.log(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url);
            console.log(
              image.name
                .split(".")
              [image.name.split(".").length - 1].toLowerCase()
            );
            if (
              image.name
                .split(".")
              [image.name.split(".").length - 1].toLowerCase() == "mp4"
            ) {
              let arr = [...allData.state];
              arr[index].file_url.push({
                videoUrl: url,
                name: image.name,
                imageOrigin: "firebase",
              });
              allData.setState(arr);
              latestData.current[index].file_url = arr[index].file_url;
            } else if (
              image.name
                .split(".")
              [image.name.split(".").length - 1].toLowerCase() == "pdf"
            ) {
              let arr = [...allData.state];
              arr[index].file_url.push({
                pdfUrl: url,
                name: image.name,
                imageOrigin: "firebase",
              });
              allData.setState(arr);
              latestData.current[index].file_url = arr[index].file_url;
              //   console.log("pdf pdf pdf ");
            } else if (
              image.name
                .split(".")
              [image.name.split(".").length - 1].toLowerCase() == "jpg" ||
              image.name
                .split(".")
              [image.name.split(".").length - 1].toLowerCase() == "png"
            ) {
              let arr = [...allData.state];
              arr[index].file_url.push({
                imageUrl: url,
                name: image.name,
                imageOrigin: "firebase",
              });
              allData.setState(arr);
              latestData.current[index].file_url = arr[index].file_url;
            }
            setLoader(false);
          });
        }
      );
    } else {
      setOpenLoader(false);
      setLoader2(false);
      // alert('please select valid format mat')
      ShowSnackbar({
        ...snackbar,
        show: true,
        msg: "Please select jpg,png,pdf or mp4 file",
        type: "error",
      });
      console.log("seleect valid +++++++++++++++++");
    }
    e.target.value = "";
  }

  function ImageGridComponant({ res }) {
    // console.log(res);

    let [imageDialog, setImageDialog] = useState(false);

    function handleClose() {
      console.log("close btn clicked");
      setImageDialog(false);
    }

    function deleteImage(img) {
      console.log(img);

      if (img.imageOrigin == "firebase") {
        setLoader(true);
        const storage = getStorage();
        const desertRef = ref(
          storage,
          img.imageUrl !== undefined
            ? img.imageUrl
            : img.videoUrl !== undefined
              ? img.videoUrl
              : img.pdfUrl
        );
        deleteObject(desertRef).then(() => {
          console.log("image deleted");
          deleteImageFromDatabase(img);
        });
      } else {
        console.log("delete excel image");
        deleteImageFromDatabase(img);
      }
    }
    const deleteImageFromDatabase = (imglink) => {
      setLoader(true);
      let imgName =
        imglink.imageUrl !== undefined ? "imageUrl" : imglink.videoUrl !== undefined ? "videoUrl" : imglink.embedUrl !== undefined ? 'embedUrl' : "pdfUrl";

      let url = `${baseURL}assesment/deleteFileUrl/${configData._id}`;
      axios
        .put(url, {
          file_url: imglink[imgName],
        })
        .then((res) => {
          setLoader(false);
          console.log(res);
          let arr = [...allData.state];
          let inarr = [];

          arr[index].file_url.forEach((re) => {
            if (re[imgName] !== imglink[imgName]) {
              inarr.push(re);
            }
          });
          arr[index].file_url = inarr;
          console.log(inarr);
          latestData.current[index].file_url = inarr;
          allData.setState(arr);
          // console.log(inarr)
        })
        .catch((err) => console.log(err));
    };

    return (
      <>
        <Grid
          item
          container
          md={12}
          sx={{
            borderRadius: "8px",
            p: 1,
            height: "60px",
            cursor: "pointer",
            border: "2px solid #E3E3E3",
          }}
        >
          <Grid item sm={3} sx={{ m: "auto" }}>
            {res.videoUrl == undefined ? <Photo /> : <OndemandVideo />}
          </Grid>
          <Grid
            onClick={() => {
              res.embedUrl !== undefined ? null : setImageDialog(true)
            }}
            item
            sm={6}
            sx={{ overflow: "hidden" }}
            className="h-100"
          >
            {res.embedUrl !== undefined ? <a href={res.embedUrl} target='_blank'>{res.name}</a> : res.name}
          </Grid>
          <Grid item sm={3} align="right" sx={{ m: "auto" }}>
            <Delete
              sx={{
                bgcolor: "#F4F4F4",
                color: "grey",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => deleteImage(res)}
            />
          </Grid>
        </Grid>
        <Dialog
          open={imageDialog}
          onClose={handleClose}
          maxWidth={"md"}
          fullWidth={true}
        >
          <Grid sx={{ height: "100vh" }}>
            <Grid sx={{ mb: 2 }} align="right">
              <DisabledByDefault
                sx={{ color: "red", cursor: "pointer", fontSize: "25px" }}
                onClick={() => handleClose()}
              />
            </Grid>
            <Container sx={{ p: 3, height: "100%" }}>
              <Grid sx={{ height: "90%" }}>
                {res.videoUrl !== undefined ? (
                  <video width="100%" height="90%" controls autoplay>
                    <source src={res.videoUrl} type="video/ogg" />
                  </video>
                ) : (
                  <>
                    {res.imageUrl !== undefined ? (
                      <img src={res.imageUrl} alt="image failed to load" />
                    ) : (
                      <>
                        {res.pdfUrl !== undefined ? (
                          <embed src={res.pdfUrl} width="100%" height="100%" />
                        ) : (
                          <></>
                          // <iframe
                          //   width="100%"
                          //   height="100%"
                          //   src={res.embedUrl}
                          //   frameborder="0" allowfullscreen
                          // ></iframe>
                          // <iframe width="100%" height="100%" src='https://www.youtube.com/embed/L1pfd9iHaWw' allowfullscreen></iframe>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Container>
          </Grid>
        </Dialog>
      </>
    );
  }
  function addembedurl(e) {
    e.preventDefault();
    console.log(embedUrl);

    let arr = [...allData.state];
    arr[index].file_url.push({
      embedUrl,
      name: 'embed video',
      imageOrigin: "excel",
    });
    allData.setState(arr);
    latestData.current[index].file_url = arr[index].file_url;
    setEmbedUrl('')
    handleClosem()


  }




  return (
    <>
      <Box
        id={`scrollbox${index}`}
        sx={{
          p: 3,
          mx: 2,
          bgcolor: "white",
          boxShadow: "0 0 5px 0 #888888",
        }}
        className={
          configData.isDuplicate !== undefined
            ? configData.isDuplicate
              ? "duplicateClass"
              : ""
            : ""
        }
      >
        <Typography align="right" sx={{ m: 0, fontSize: "16px" }}>
          {mode == "allEdit" ? index + 1 : null}
        </Typography>
        <Typography>Learning Area</Typography>
        {/* main grid */}
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
          }}

        >
          <Grid className="configuration_topCon" item>
            <Select
              required
              fullWidth
              disabled={mode == "allEdit"}
              defaultValue={latestData.current[index].learning_area}
              onChange={(e) => {
                latestData.current[index].learning_area = e.target.value;
              }}
            >
              <MenuItem value="Practical">Practical</MenuItem>
              <MenuItem value="Sensorial">Sensorial</MenuItem>
              <MenuItem value="Language">Language</MenuItem>
              <MenuItem value="Maths">Maths</MenuItem>
              <MenuItem value="Culture">Culture</MenuItem>
            </Select>
          </Grid>

          <Grid item md={1}>
            {!firstDialog ? (
              <Add
                onClick={() => setFirstDialog(true)}
                sx={{
                  cursor: "pointer",
                  m: 3,
                  bgcolor: "#169AC9",
                  borderRadius: "50%",
                  color: "white",
                }}
              />
            ) : null}
          </Grid>

          {firstDialog ? (
            <>


              <div className="w-100">

                <form onSubmit={addAssementActivity}>
                  <div>

                    <div className="d-flex align-items-center">

                      <div className=" my-3 d-flex justify-content-between align-items-center configuration_topCon">
                        <div item className="me-3 w-100">
                          <label>Section Name</label>
                        </div>

                        <div className="d-sm-flex align-items-center">
                          <div>
                            <Autocomplete
                              required
                              fullWidth
                              sx={{ width: "270px" }}
                              defaultValue={{
                                section_name:
                                  latestData.current[index].section_name,
                              }}
                              onChange={(event, newValue, result) => {
                                document.querySelector(`.subsectionCls${index} button`).click()
                                latestData.current[index].sub_section_name = ""
                                setCurrentSection(newValue)
                                if (newValue !== null) {
                                  getAllSubsection(newValue._id)
                                }
                                else {
                                  setAllsubsectionData([]);
                                }


                                newValue !== null
                                  ?
                                  (latestData.current[index].section_name =
                                    newValue.section_name
                                  )
                                  : (latestData.current[index].section_name = "");
                                // allData.setState(arr);
                              }}
                              getOptionLabel={(option) =>
                                option.section_name.charAt(0).toUpperCase() +
                                option.section_name.toLowerCase().slice(1)
                              }
                              options={allMaterial.allSections}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Section Name"
                                  required
                                />
                              )}
                            />
                          </div>



                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="mx-3">
                          <Add
                            onClick={() => setSec_name_dialog(true)}
                            sx={{ cursor: "pointer", fontSize: "30px" }}
                          />
                        </div>
                        {currentSection !== null && currentSection.section_name !== '' ?
                          <AddSubsectionIntoSection fun={newfun} sectionData={currentSection} allSubsection={allMaterial.allSubSections} />
                          : null}</div>
                    </div>

                    <div className="mb-3 configuration_topCon d-flex justify-content-between align-items-center ">
                      <div className="w-100">
                        <label>
                          Sub Section Name <small>(optional)</small>
                        </label>
                      </div>

                      <div className="d-sm-flex align-items-center">
                        <div>
                          <Autocomplete
                            className={`subsectionCls${index}`}
                            sx={{ width: "270px" }}
                            inputvalue=''
                            defaultValue={{
                              subsection_name:
                                latestData.current[index].sub_section_name !=
                                  undefined
                                  ? latestData.current[index].sub_section_name
                                  : "",
                            }}
                            onChange={(event, val) => {

                              val !== null
                                ? (latestData.current[index].sub_section_name =
                                  val.subsection_name)
                                : (latestData.current[index].sub_section_name =
                                  "");
                              // allData.setState(arr);
                            }}
                            getOptionLabel={(option) =>
                              option.subsection_name.charAt(0).toUpperCase() +
                              option.subsection_name.toLowerCase().slice(1)
                            }
                            options={allSubsectionData}
                            renderInput={(params) => (
                              <TextField {...params} label="Sub Section Name" />
                            )}
                          />
                        </div>

                        <div>
                          {/* <Add
                          onClick={() => setSubsec_name_dialog(true)}
                          sx={{
                            cursor: "pointer",
                            mx: 2,
                            fontSize: "30px",
                          }}
                        /> */}
                        </div>
                      </div>
                    </div>

                    <div className="configuration_topCon d-flex justify-content-between align-items-center ">
                      <div className="w-100">
                        <label>Assessment Name</label>
                      </div>

                      <div>
                        <TextField
                          placeholder="assessment name"
                          defaultValue={latestData.current[index].assesment_name}
                          onChange={(e) => {

                            latestData.current[index].assesment_name =
                              e.target.value;
                          }}
                          sx={{ width: "270px" }}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <Button type="submit">
                    <Add
                      sx={{
                        cursor: "pointer",
                        bgcolor: "#169AC9",
                        borderRadius: "50%",
                        color: "white",
                      }}
                    />
                  </Button>
                </form>
              </div>


            </>

          ) : null}
        </Grid>
        {/* add section name dialog  */}
        <Dialog fullWidth={true} open={sec_name_dialog}>
          <Typography variant="h4" align="center" sx={{ my: 3 }}>
            Add Section
          </Typography>
          <form onSubmit={addSectionName}>
            <Container>
              <TextField
                fullWidth
                required
                label="Section Name"
                onChange={(e) => setSectionName(e.target.value)}
              />
            </Container>
            <Container sx={{ my: 3 }} align="right">
              <Button
                onClick={() => {
                  setSec_name_dialog(false);
                  setSectionName("");
                  setSectionLoader(false);
                }}
                className="cancel_btn"
              >
                Cancel
              </Button>
              {!sectionLoader ? (
                <Button className="btn_primary" sx={{ mx: 2 }} type="submit">
                  Add
                </Button>
              ) : (
                <Button>
                  <CircularProgress mt={3} />
                </Button>
              )}
            </Container>
          </form>
        </Dialog>
        {/* add subsection name dialog  */}
        <Dialog fullWidth={true} open={subSec_name_dialog}>
          <Typography variant="h4" align="center" sx={{ my: 3 }}>
            Add Sub Section
          </Typography>
          <form onSubmit={addSubSectionName}>
            <Container>
              <TextField
                fullWidth
                required
                label="Sub Section Name"
                sx={{ my: 3 }}
                onChange={(e) => setSubSectionName(e.target.value)}
              />
            </Container>
            <Container sx={{ my: 3 }} align="right">
              <Button
                onClick={() => {
                  setSubsec_name_dialog(false);
                  setSubSectionName("");
                  setSectionLoader(false);
                }}
                className="cancel_btn"
              >
                Cancel
              </Button>
              {!sectionLoader ? (
                <Button className="btn_primary" sx={{ mx: 2 }} type="submit">
                  Add
                </Button>
              ) : (
                <Button>
                  <CircularProgress mt={3} />
                </Button>
              )}
            </Container>
          </form>
        </Dialog>

        {/* activity section starts here  */}
        {secondDialog ? (
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                color: "grey",
                fontSize: "15px",
                textTransform: "capitalize",
              }}
            >
              {/* {refData.current.assesment_name}: */}Assessment name
            </Typography>

            <Grid container>
              {allMaterial.allActivities.map((res, ind) => {
                return (
                  <Grid key={ind} item md={3} sx={{ my: 1 }}>
                    <Typography
                      sx={{
                        color: "grey",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        defaultChecked={
                          latestData.current[index].assesmentActivity.indexOf(
                            res.activity_name
                          ) !== -1
                        }
                        onChange={(e) => activitycheckbox(e, res.activity_name)}
                        type="checkbox"
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{ mx: 1, textTransform: "capitalize" }}
                      >
                        {res.activity_name}
                      </Typography>
                      {/* {mode !== "allEdit" ? (
                        <Delete
                          //   onClick={() => deleteActivity(ind, res)}
                          sx={{ color: "grey", cursor: "pointer" }}
                        />
                      ) : null} */}
                    </Typography>
                  </Grid>
                );
              })}

              <Grid item md={3} sx={{ my: 1 }}>
                <Button aria-describedby={id2} onClick={handleClick2}>
                  <Typography
                    sx={{
                      color: "grey",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Add
                      sx={{
                        cursor: "pointer",
                        bgcolor: "#169AC9",
                        borderRadius: "50%",
                        color: "white",
                      }}
                    />
                    <Typography
                      sx={{
                        mx: 1,
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                    >
                      Add an Option
                    </Typography>
                  </Typography>
                </Button>

                <Popover
                  id={id2}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={handleClose2}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <form onSubmit={addActivity}>
                    <Grid container sx={{ p: 3, width: "300px" }} spacing={2}>
                      <Grid item md={12}>
                        <TextField
                          value={newActivity}
                          required
                          onChange={(e) => {
                            e.target.value == " "
                              ? (e.target.value = "")
                              : setNewActivity(e.target.value);
                          }}
                          fullWidth
                          placeholder="Enter Option"
                        />
                        <br />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          className="cancel_btn"
                          onClick={() => {
                            handleClose2();
                            setNewActivity("");
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn_primary"
                          sx={{ mx: 1 }}
                          type="submit"
                        >
                          Add Option
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Popover>
              </Grid>
            </Grid>
          </Box>
        ) : null}

        {/* age section starts here */}
        {firstDialog ? (
          <Box sx={{ mt: 2 }}>
            <Typography>
              Select age's to apply this assessment on:{" "}
            </Typography>

            <Grid container>
              {allMaterial.allAges.map((res, ageInd) => {
                return (
                  <Grid item md={3} sx={{ my: 1 }}>
                    <Typography
                      sx={{
                        color: "grey",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        onChange={(e) => agegroupcheckbox(e, res.age)}
                        type="checkbox"
                        defaultChecked={
                          latestData.current[index].ageGroup.indexOf(
                            res.age
                          ) !== -1
                        }
                      />
                      <Typography variant="subtitle1" sx={{ mx: 1 }}>
                        {res.age} year old
                      </Typography>
                      {/* {mode !== "allEdit" ? (
                        <Delete
                          sx={{ cursor: "pointer" }}
                          // onClick={() => deleteAge(res, ageInd)}
                        />
                      ) : null} */}
                    </Typography>
                  </Grid>
                );
              })}

              <Grid item md={3} sx={{ my: 1 }}>
                <Button aria-describedby={id} onClick={handleClick}>
                  <Typography
                    sx={{
                      color: "grey",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Add
                      sx={{
                        cursor: "pointer",
                        bgcolor: "#169AC9",
                        borderRadius: "50%",
                        color: "white",
                      }}
                    />
                    <Typography
                      sx={{
                        mx: 1,
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                    >
                      Add an Age
                    </Typography>
                  </Typography>
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <form onSubmit={addAge}>
                    <Grid container sx={{ p: 3, width: "300px" }} spacing={2}>
                      <Grid item md={12}>
                        <TextField
                          value={newAge}
                          required
                          type="number"
                          onChange={(e) => setNewAge(e.target.value)}
                          fullWidth
                          placeholder="Enter Age"
                        />
                        <br />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="outlined"
                          className="cancel_btn"
                          onClick={() => {
                            handleClose();
                            setNewAge("");
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn_primary"
                          sx={{ mx: 1 }}
                          type="submit"
                        >
                          Add age
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Popover>
              </Grid>
            </Grid>

            <div className="d-flex my-4 align-items-center" style={{ maxWidth: '580px' }}>
              <div className="w-25">
                Goal :-
              </div>

              <div className="d-flex align-items-center w-75">

                <Autocomplete
                  sx={{ width: "270px" }}
                  defaultValue={{
                    goal_name:
                      latestData.current[index].goal_name !=
                        undefined
                        ? latestData.current[index].goal_name
                        : "",
                  }}
                  onChange={(event, val) => {
                    console.log(val)
                    // let arr = [...allData.state];
                    val !== null
                      ? (latestData.current[index].goal_name =
                        val.goal_name)
                      : (latestData.current[index].goal_name =
                        "");
                    // allData.setState(arr);
                  }}
                  getOptionLabel={(option) =>
                    option.goal_name
                  }
                  options={allGoals}
                  renderInput={(params) => (
                    <TextField {...params} label="Goal Name" />
                  )}
                />

                <AddGoal getAllGoals={functions.getAllGoals} />
              </div>

            </div>

            <input
              defaultChecked={
                latestData.current[index].enable_educator_to_comment
              }
              onChange={(e) => {
                // let arr = [...allData.state];
                latestData.current[index].enable_educator_to_comment =
                  e.target.checked;
                // allData.setState(arr);
              }}
              type="checkbox"
            />

            <span> Enable Educator to comment on this</span>
            <br />
            <input
              defaultChecked={latestData.current[index].activity_reference}
              onChange={(e) => {
                // let arr = [...allData.state];
                latestData.current[index].activity_reference = e.target.checked;
                // allData.setState(arr);
              }}
              type="checkbox"
            />
            <span className="mx-1">Activity Reference</span>

            <Box mt={2}>
              <span>Assessment Information </span>
              <br />
              <textarea
                defaultValue={latestData.current[index].config_info}
                onChange={(e) => latestData.current[index].config_info = e.target.value}
                className="w-100 mt-2" rows={5}>

              </textarea>
            </Box>

          </Box>
        ) : null}
        <Grid item md={12}>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {configData.file_url.map((res) => {
              return (
                <Grid item md={6}>
                  <ImageGridComponant res={res} />
                </Grid>
              );
            })}
          </Grid>

          <Box
            sx={{
              border: "2px dashed #1799CD",
              borderRadius: "10px",
              mt: 5,
            }}
          >
            <input
              onChange={(e) => inpChange(e, index)}
              type="file"
              id={`actual-btn${index}`}
              hidden
            />
            <label
              for={`actual-btn${index}`}
              className="w-100 text-center p-3"
              role="button"
            >
              Choose File
            </label>
          </Box>
          <Box>
            <Button
              className="btn_primary mt-3"
              variant="primary"
              onClick={handleShowm}
            >
              Upload url
            </Button>

            <Modal show={showm} onHide={handleClosem}>
              <Typography variant="h5" className="my-3" align="center">
                Add url
              </Typography>
              <form onSubmit={addembedurl}>
                <Box className="px-3">
                  <TextField
                    required
                    value={embedUrl}
                    onChange={(e) => setEmbedUrl(e.target.value)}
                    placeholder="URL"
                    fullWidth
                  />
                </Box>
                <Box align="right" className="p-3">
                  <Button
                    className="cancel_btn me-3"
                    variant="secondary"
                    onClick={handleClosem}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn_primary"
                    variant="primary"
                    type="sumbit"
                  >
                    Save
                  </Button>
                </Box>
              </form>
            </Modal>
          </Box>
        </Grid>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              autoHideDuration={3000}
              open={show}
              onClose={closeSnackbar}
              key={vertical + horizontal}
            >
              <Alert
                onClose={closeSnackbar}
                severity={type}
                sx={{ width: "100%" }}
              >
                {msg}
              </Alert>
            </Snackbar> */}
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
      </Box >
    </>
  );
};
