import { Add, Edit, ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Grid,
  Input,
  TextField,
  Box,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import { Container } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Routines } from "../Routines/Routines";
import "../../style.css";
import { SubRecord } from "./SubRecord";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { baseURL } from "../../auth/GlobalUrl";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { SnackBar } from "../SnackBar";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

export const AddRecord = () => {
  let dat = new Date();
  let yr = dat.getFullYear();
  let mnt = dat.getMonth();
  let day = dat.getDate();
  let fullDate = `${yr}-${mnt + 1 < 10 ? "0" : ""}${mnt + 1}-${
    day < 10 ? "0" : ""
  }${day}`;

  let [recordPage, setRecordPage] = useState(true);
  let [newRecordName, setNewRecordName] = useState("");
  let [allSubData, setAllSubData] = useState();
  let [arr2, setArr2] = useState([]);
  let [checkedChilds, setCheckedChilds] = useState([]);
  let [recordLoader, setRecordLoader] = useState(true);
  let [allChild, setAllChild] = useState([]);
  let [dateAndTime, setDateAndTime] = useState({
    created_at: fullDate,
    time: `${new Date().getHours() < 10 ? "0" : ""}${new Date().getHours()}:${
      new Date().getMinutes() < 10 ? "0" : ""
    }${new Date().getMinutes()}`,
  });
  let navigate = useNavigate();

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  const [value, setValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  let roomId = useParams();
  

  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  const onSearch = (e) => {
    if (e.target.value !== " ") {
      setValue(e.target.value);
      const filterTable = allChild.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]);
    } else {
      setValue(e.target.value);
      setAllChild(...data);
    }
  };
  let tenant_id = localStorage.getItem("tenant_id");

  useEffect(() => {
    getAllRecords();
    getAllChild();
  }, []);
  function getAllRecords() {
    let url = `${baseURL}record/get/allRecord/${tenant_id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        setArr2(res.data.data);
        setRecordLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function showSnackbarMessage(msg, type) {
    ShowSnackbar({
      show: true,
      vertical: "top",
      horizontal: "right",
      msg,
      type,
    });
  }

  function getAllChild() {
    console.log("pppppppppppp");
    let url = `${baseURL}room/getRoom/${roomId.id}`;
    axios
      .get(url)
      .then((res) => {
        setAllChild(res.data.data[0].child_data);
        console.log(res.data.data[0].child_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // pop functions
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNewRecordName("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function gotoSubRecord(e) {
    setAllSubData(e);
    setRecordPage(false);
  }

  function addRecordName(e) {
    console.log("clicked add");
    e.preventDefault();
    console.log(arr2);
    let num = 0;
    arr2.forEach((res) => {
      if (
        res.record_name.toLowerCase().trim() ==
        newRecordName.toLowerCase().trim()
      ) {
        num++;
      }
    });
    if (num == 0) {
      handleClose();
      let url = `${baseURL}record/addRecord`;
      axios
        .post(url, { record_name: newRecordName, tenant_id })
        .then((res) => {
          console.log(res.data.data);
          setArr2([...arr2, res.data.data]);
          setNewRecordName("");
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: "Record added successfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Record already exist",
        type: "error",
      });
    }
  }
  function childClick(e, id) {
    if (e.target.checked) {
      setCheckedChilds([...checkedChilds, id]);
    } else {
      let arr3 = [...checkedChilds];
      arr3.splice(arr3.indexOf(id), 1);
      setCheckedChilds(arr3);
    }
    console.log(checkedChilds);
  }

  function DataGrid({ other, id }) {
    let [expand, setExpand] = useState(false);
    return (
      <>
        <Box
          sx={{ display: "flex", justifyContent: "center", py: 2 }}
          className="me-lg-5 me-0"
          spacing={1}
        >
          <Grid item>
            <Edit sx={{ color: "brown" }} />
          </Grid>
          <Grid item>
            <Add sx={{ color: "red", mx: 2 }} />
          </Grid>
          <Grid item>
            {expand ? (
              <ExpandLess
                onClick={() => setExpand(!expand)}
                sx={{ color: "orange", cursor: "pointer" }}
              />
            ) : (
              <ExpandMore
                onClick={() => setExpand(!expand)}
                sx={{ color: "orange", cursor: "pointer" }}
              />
            )}
          </Grid>
        </Box>
        <br />
        {expand ? (
          <>
            <Grid
              className="m-auto pb-3 d-flex flex-column-reverse"
              align="center"
            >
              {other.map((res2, index) => {
                // console.log(res2.created_at)
                if (fullDate == res2.created_at) {
                  return index !== other.length - 1 ? (
                    <Box>
                      <Grid sx={{ color: "#5B940E" }} key={index}>
                        {res2.record_name}
                      </Grid>
                      <Grid
                        variant="small"
                        sx={{ fontSize: "14px", color: "#9A9A9A", mb: 2 }}
                        key={index}
                      >
                        {res2.time} , {res2.educator_name}
                      </Grid>
                    </Box>
                  ) : null;
                }
              })}
            </Grid>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Typography
                sx={{ width: "100%", m: 5 }}
                align="right"
                onClick={() => navigate(`/allRecord/${id}`)}
              >
                view all
              </Typography>
            </Box>
          </>
        ) : null}
      </>
    );
  }

  return (
    <Sidebar>
      <Grid px={3}>
          <Link to={`/allchildforroutine/${roomId.id}`}>
            <ArrowBackIos />
          </Link>
        </Grid>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

      <Grid container px={3} py={3}>
        <Grid item md={5} xs={12}>
          <TextField
            id="input-with-icon-textfield"
            onChange={onSearch}
            value={value}
            placeholder="Search anything"
            size="medium"
            sx={{ backgroundColor: "white", width:'100%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item lg={8} sm={12}>
            <Grid
              container
              sx={{
                borderBottom: "1px solid #CCCCCC",
                py: 4,
                px: 2,
                bgcolor: "white",
              }}
            >
              <Grid item md={5}>
                <input type='checkbox'/> <span>Select All</span>
              </Grid>
              <Grid item md={3} sx={{ color: "#3FAEED" }}>
                                                            
              </Grid>
              {/* <Grid item md={3} sx={{ color: "#3FAEED" }}>
                Show all
              </Grid> */}
            </Grid>

            {allChild.map((res, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    borderBottom: "1px solid #CCCCCC",
                    px: 2,
                    bgcolor: "white",
                  }}
                  className="addRecord_table_grid"
                >
                  <Box
                    item
                    sm={4}
                    py={2}
                    container
                    sx={{ display: "flex" }}
                    spacing={1}
                  >
                    <Grid item sm={3}>
                      <input
                        type="checkbox"
                        checked={checkedChilds.indexOf(res._id) != -1}
                        onClick={(e) => childClick(e, res._id)}
                      />
                    </Grid>
                    <Grid item px={3}>
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        src={
                          res.child_profileUrl
                            ? res.child_profileUrl
                            : blankImage
                        }
                        alt="#"
                      />
                    </Grid>
                    <Grid item>{res.child_name}</Grid>
                  </Box>

                  <Box align="center" py={2}>
                    {res.child_records.length !== 0 ? (
                      <span style={{ color: "#5B940E" }}>
                        {
                          res.child_records[res.child_records.length - 1]
                            .record_name
                        }
                        <br />
                      </span>
                    ) : null}

                    <Typography
                      variant="small"
                      sx={{ fontSize: "14px", color: "#9A9A9A" }}
                    >
                      {res.child_records.length !== 0
                        ? res.child_records[res.child_records.length - 1].time
                        : null}{" "}
                      ,{" "}
                      {res.child_records.length !== 0
                        ? res.child_records[res.child_records.length - 1]
                            .educator_name
                        : null}
                    </Typography>
                  </Box>
                  <DataGrid other={res.child_records} id={res._id} />
                </Box>
              </>
            ))}
          </Grid>

          <Grid item lg={4} sm={12}>
            <Grid sx={{ bgcolor: "white" }} container>
              <Box
                container
                className="w-100 d-md-flex justify-content-around pt-3 px-3"
              >
                <Box sx={{ mb: 2 }}>
                  <input
                    type="time"
                    value={dateAndTime.time}
                    className="btn_primary date_btn"
                    onChange={(e) =>
                      setDateAndTime({ ...dateAndTime, time: e.target.value })
                    }
                  />
                </Box>

                <Box>
                  <input
                    type="date"
                    value={dateAndTime.created_at}
                    className="btn_primary date_btn"
                    onChange={(e) =>
                      setDateAndTime({
                        ...dateAndTime,
                        created_at: e.target.value,
                      })
                    }
                  />
                </Box>
              </Box>

              {recordPage ? (
                <>
                  {recordLoader ? (
                    <Box sx={{ my: 5, width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <Grid container sx={{ mt: 3 }}>
                      {arr2.map((name) => {
                        return (
                          <Grid
                            item
                            className="dd"
                            onClick={(e) => gotoSubRecord(name)}
                          >
                            {name.record_name}
                          </Grid>
                        );
                      })}
                      <br />
                     
                    </Grid>
                  )}

                </>
              ) : (
                <SubRecord
                  stateData={allSubData}
                  setRecordPage={setRecordPage}
                  checkedChilds={checkedChilds}
                  dateAndTime={{ content: dateAndTime, set: setDateAndTime }}
                  setCheckedChilds={setCheckedChilds}
                  getAllChild={getAllChild}
                  showSnackbarMessage={showSnackbarMessage}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Sidebar>
  );
};
