import {
  CircularProgress,
  LinearProgress,
  Typography,
  Grid,
  Backdrop,
  Box,
  Button,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import { contextData } from "../../Router";
import { SnackBar } from "../SnackBar";
import { ConfigurationCommonComp } from "./ConfigurationCommonFile";

export const EditConfiguration = () => {
  let [assesmentData, setAssesmentData] = useState({});
  let [loader, setLoader] = useState(true);
  let { id } = useParams();
  let snackBarState = useContext(contextData);
  let [openLoader, setOpenLoader] = useState(false);
  let [allGoals, setAllGoals] = useState([])

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });
  // starts
  let num = 0;
  const [allConfiguration, setAllConfiguration] = useState([]);
  const latestData = useRef([]);
  const [allActAgeSecSub, setAllActAgeSecSub] = useState({});

  const tenant_id = localStorage.getItem("tenant_id");

  function getAllActAgeSecSub() {
    let url = `${baseURL}home/getActAgeSecSub/${tenant_id}`;
    axios
      .get(url)
      .then((res) => {
        setAllActAgeSecSub(res.data.data);
        console.log(res.data.data);

        num++;
        if (num == 2) {
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  }

  // ends
  async function getAllGoals() {
    try {
      let res = await axios.get(Apis.GOAL.getAll + tenant_id)
      console.log(res.data.data)
      setAllGoals([...res.data.data]);

      console.log(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAssesmentById(id);
    getAllActAgeSecSub();
    getAllGoals()

  }, []);

  function getAssesmentById(id) {

    fetch(Apis.ASSESSMENT.getById + id)
      .then((res) => res.json())
      .then((res2) => {
        console.log(res2.data);
        setAssesmentData(res2.data);
        setAllConfiguration([res2.data]);
        latestData.current = [res2.data];
        num++;
        if (num == 2) {
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let navigate = useNavigate();

  function fun() {
    navigate("/configuration");
  }
  async function updateAssesment() {
    setOpenLoader(true);
    console.log(latestData.current);

    let d = latestData.current[0];
    delete d._id;
    delete d.updated_at;
    delete d.created_at;
    delete d.__v;

    console.log(d);

    let url = `${baseURL}assesment/update/${id}`;
    axios
      .put(url, d)
      .then((res) => {
        setOpenLoader(false);
        snackBarState.setState(true);
        navigate("/configuration");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.errormessage.details !== undefined) {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: err.response.data.errormessage.details[0].message,
            type: "error",
          });
        } else {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: err.response.data.errormessage,
            type: "error",
          });
        }
        setOpenLoader(false);
      });
  }

  return (
    <>
      <Sidebar>
        <Grid px={3}>
          <Link to="/configuration">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        <Typography sx={{ textAlign: "center", my: 2 }} variant="h5">
          Edit Assessment
        </Typography>
        {loader ? (
          <LinearProgress />
        ) : (
          <>
            {/* starts  */}

            {allConfiguration.map((res, index) => {
              return (
                <Box mb={2}>
                  <ConfigurationCommonComp
                    index={index}
                    allGoals={allGoals}
                    latestData={latestData}
                    allData={{
                      state: allConfiguration,
                      setState: setAllConfiguration,
                    }}
                    mode="edit"
                    functions={{ getAllMaterial: getAllActAgeSecSub, getAllGoals: getAllGoals }}
                    configData={res}
                    allMaterial={allActAgeSecSub}
                  />
                </Box>
              );
            })}
            <Grid
              sx={{ display: "flex", justifyContent: "flex-end", mx: 5, my: 3 }}
            >
              <Button
                onClick={() => navigate("/configuration")}
                sx={{ mx: 2 }}
                className="cancel_btn"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={updateAssesment}
                className="btn_primary"
              >
                Done
              </Button>
            </Grid>

            {/* ends  */}

            {/* <AddConfiguration
              fun={fun}
              alldata={assesmentData}
              submitFun={updateAssesment}
              mode="edit"
              id={id}
            /> */}

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openLoader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
          </>
        )}
      </Sidebar>
    </>
  );
};
