import { Container } from "@mui/system";
import { Sidebar } from "../../components/Sidebar";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Add, AddCircle, Delete, Edit, UploadFile } from "@mui/icons-material";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import axios from "axios";
import { AddAssesmentHome } from "./AddConfigurationHome";
import * as XLSX from "xlsx";
import { SnackBar } from "../SnackBar";
import { contextData } from "../../Router";
import "../../style.css";
import { DeleteAssessment } from "./DeleteAssessment";

export const Configuration = () => {
  let [allAssesments, setAllAssesments] = useState([]);
  let tenant_id = localStorage.getItem("tenant_id");
  let [filteredAssesment, setFilteredAssesment] = useState([]);
  let [openDelete, setOpenDelete] = useState(false);
  let [loader, setLoader] = useState(true);
  let [excelFile, setExcelFile] = useState("");
  let [deletedIndex, setDeletedIndex] = useState({ id: "", index: "" });
  let snackBarState = useContext(contextData);
  let [selectedFile, setSelectedFile] = useState("");
  let [selectedArea, setSelectedArea] = useState("Practical");
  let [allConfig, setAllConfig] = useState([]);

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  useEffect(() => {
    getAllAssesments();

    if (snackBarState.state) {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "assessment saved successfully",
        type: "success",
      });
      snackBarState.setState(false);
    }
  }, []);

  function getAllAssesments() {
    axios
      .get(Apis.ASSESSMENT.getAllByTenant_id + tenant_id)
      .then((res2) => {
        console.log(res2.data.data);
        setAllAssesments(res2.data.data);
        let dataarr = res2.data.data.filter(
          (r) => r.learning_area == "Practical"
        );
        setAllConfig(
          res2.data.data.filter(
            (e) => e.learning_area.toLowerCase() == "practical"
          )
        );
        setFilteredAssesment(dataarr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteAssesment(assid, index) {
    setOpenDelete(false);

    setAllAssesments(allAssesments.filter((e) => e._id !== assid));
    let arr = [...filteredAssesment];
    arr.splice(index, 1);
    setFilteredAssesment(arr);

    axios.delete(Apis.ASSESSMENT.deleteAssessment + assid).then(() => {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Assessment deleted successfully",
        type: "error",
      });
    });
  }

  function navClick(d) {
    document.getElementById("searchConfigInp").value = "";
    setSelectedArea(d.target.textContent);
    let btn = document.getElementsByClassName("nav_item");
    console.log(d.target.textContent)
    console.log(allAssesments)
    let all = [...allAssesments];
    let dataarr = all.filter(
      (r) => r.learning_area.toLowerCase() == d.target.textContent.toLowerCase()
    );
    console.log(dataarr)
    setFilteredAssesment(dataarr);
    setAllConfig(dataarr);
    let i = 0;
    for (i = 0; i < btn.length; i++) {
      btn[i].style.color = "black";
      btn[i].style.borderBottom = "none";
    }
    d.target.style.color = "#FA981D";
    d.target.style.borderBottom = "1px solid #FA981D";
  }
  function inpChange(e) {
    let file = e.target.files[0];
    setSelectedFile(file.name);
    console.log(file);
    setExcelFile(file);
    return;
  }
  function uploadExcel() {
    if (excelFile == "" || excelFile == undefined) {
      console.log("no selected");
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Please select file first",
        type: "error",
      });
    } else if (
      excelFile.name.split(".")[excelFile.name.split(".").length - 1] == "xlsx"
    ) {
      let file = excelFile;
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = () => {
        let workbook = XLSX.read(fileReader.result, { type: "binary" });
        let sheetNames = workbook.SheetNames;
        let excelData = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetNames[0]]
        );

        if (excelData.length == 0) {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: "Excel file is empty",
            type: "error",
          });
          // setLoader(false);
          return;
        }


        console.log(excelData);
        let finalArray = [];

        let num = 0;
        let checkArray = [
          "learning_area",
          "section_name",
          "assesment_name",
          "ageGroup",
          "assesmentActivity",
          "goal_name"
        ];

        excelData.forEach((outer) => {
          let ob = Object.keys(outer);
          // console.log(ob);
          let arr = [];

          checkArray.forEach((check) => {
            if (!ob.includes(check)) {
              num++;
            }
          });

          if (num !== 0) {
            ShowSnackbar({
              show: true,
              vertical: "top",
              horizontal: "right",
              msg: `Some required fields are not present`,
              type: "error",
            })
            return
          }
          ob.forEach((e) => {
            if (e.includes("url")) {
              arr.push({
                imageUrl: outer[e],
                name: "this is excel image",
                imageOrigin: "excel",
              });
            }
          });
          if (
            allAssesments.findIndex(
              (e) =>
                e.learning_area == outer.learning_area &&
                  e.assesment_name.trim().toLowerCase() ==
                  outer.assesment_name.trim().toLowerCase() &&
                  e.section_name.trim().toLowerCase() ==
                  outer.section_name.trim().toLowerCase() &&
                 ( !outer.sub_section_name ?
                  "" == e.sub_section_name.trim().toLowerCase()
                  : e.sub_section_name.trim().toLowerCase() ==
                  outer.sub_section_name.trim().toLowerCase())

            ) !== -1
          ) {
          } else {
            finalArray.push({
              learning_area: outer.learning_area?.trim() || "",
              assesmentActivity:
                outer.assesmentActivity !== undefined
                  ? outer.assesmentActivity
                    .split(",")
                    .map((e) => e.toLowerCase()) || []
                  : null,
              ageGroup:
                outer.ageGroup !== undefined
                  ? typeof outer.ageGroup == "number"
                    ? [outer.ageGroup]
                    : outer.ageGroup.split(",").map((r) => Number(r)) || []
                  : null,
              section_name: outer.section_name.trim().toLowerCase() || "",
              assesment_name: outer.assesment_name.trim().toLowerCase() || "",
              enable_educator_to_comment:
                outer.enable_educator_to_comment !== undefined
                  ? outer.enable_educator_to_comment
                  : true,
              sub_section_name:
                outer.sub_section_name !== undefined
                  ? outer.sub_section_name.trim().toLowerCase()
                  : "",
              tenant_id,
              activity_reference:
                outer.activity_reference !== undefined
                  ? outer.activity_reference
                  : true,
              file_url: arr,
              config_info: outer.config_info !== undefined ? outer.config_info : '',
              goal_name: outer.goal_name !== undefined ? outer.goal_name : ''
            });
          }
          // console.log(outer);
        });
        let arr66 = [];
        // check duplicacy of objects inside final array before saving to database
        finalArray.forEach((er, index) => {
          if (
            finalArray.findIndex(
              (e) =>
                e.learning_area == er.learning_area &&
                e.section_name.trim().toLowerCase() ==
                er.section_name.trim().toLowerCase() &&
                e.assesment_name.trim().toLowerCase() ==
                er.assesment_name.trim().toLowerCase() &&
                e.sub_section_name.trim().toLowerCase() ==
                er.sub_section_name.trim().toLowerCase()
            ) == index
          ) {
            arr66.push(er);
          }
        });
        if (num == 0) {
          let dd = []
          arr66.forEach(res => {
            if (res.goal_name.toString().trim() !== '') {
              dd.push(res.goal_name)
            }
          })
          if (arr66.length == 0) {
            ShowSnackbar({
              show: true,
              vertical: "top",
              horizontal: "right",
              msg: `All assessments are already exist`,
              type: "error",
            })
            return
          }

          if (dd.length !== arr66.length) {
            ShowSnackbar({
              show: true,
              vertical: "top",
              horizontal: "right",
              msg: "Some Goal field is missing",
              type: "error",
            });
            return
          }
          else {
            let goals = []
            console.log(dd)
            dd.forEach((resee, index) => {
              if (dd.indexOf(resee) == index) {
                goals.push(resee)
              }
            })

            axios.post(Apis.GOAL.addBulkGoalForExcel, { goals, tenant_id }).then((res) => {
              console.log(res)
            })
              .catch(err => console.log(err))
            console.log(dd, 'okokokokokokok')
          }
          setLoader(true);
          let url = `${baseURL}assesment/addBulkData`;
          axios
            .post(url, { data: arr66 })
            .then((res) => {
              //  let rrr=document.getElementById('actual-btn_excel')
              //  console.log(rrr)
              arr66.length == 0
                ? ShowSnackbar({
                  show: true,
                  vertical: "top",
                  horizontal: "right",
                  msg: `All assessment are already exist`,
                  type: "error",
                })
                : ShowSnackbar({
                  show: true,
                  vertical: "top",
                  horizontal: "right",
                  msg: `${arr66.length} assessment uploaded successfully`,
                  type: "success",
                });

              console.log(res);
              getAllAssesments();
              setLoader(false);
              setSelectedFile("");
              setExcelFile("");
            })
            .catch((err) => console.log(err));
        } else {
          setLoader(false);
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: "Some data is missing",
            type: "error",
          });
        }
      };
    } else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Please select xlsx file",
        type: "error",
      });
    }
  }

  function configSearch(e) {
    setFilteredAssesment(
      allConfig.filter(
        (res) =>
          res.assesment_name
            .trim()
            .toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          res.section_name
            .trim()
            .toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
      )
    );
    // setFilteredAssesment
  }

  return (
    <Sidebar>
      {/* card section starts here */}
      <Box className="d-sm-flex justify-content-between" px={2} mt={2}>
        <Box sx={{ alignSelf: "flex-start", width: "100%", pr: 3 }}>
          <TextField
            id="searchConfigInp"
            onChange={configSearch}
            className="searchInput"
            placeholder="Search assessment"
            sx={{ bgcolor: "white" }}
          />
        </Box>

        <Grid className="d-flex justify-content-end align-items-center">
          {filteredAssesment.length !== 0 ? (
            <Box className="me-5">
              <Link to={`/editallconfiguration/${selectedArea}`}>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  className="btn_primary"
                >
                  <Edit sx={{ fontSize: "15px", mr: 2 }} /> {selectedArea}
                </Button>
              </Link>
            </Box>
          ) : null}

          <Box>
            <Link to="/addconfiguration">
              <AddCircle sx={{ fontSize: "30px" }} />
            </Link>
          </Box>
        </Grid>
      </Box>

      {/* excel button */}

      {loader ? (
        <Box sx={{ mt: 4, px: 3 }}>
          <LinearProgress sx={{ color: "red", bgcolor: "skyblue" }} />
        </Box>
      ) : (
        <Box>
          <Box sx={{ mt: 4 }}>
            <Box px={2}>
              <Grid
                container
                sx={{
                  bgcolor: "#FCFCFC",
                  p: 2,
                  boxShadow: "0 0 5px 0 #888888",
                  alignItems: "center",
                }}
              >
                <Grid item lg={2} sm={4}>
                  <span className="nav_item active" onClick={navClick}>
                    Practical
                  </span>
                </Grid>
                <Grid item lg={2} sm={4}>
                  <span className="nav_item" onClick={navClick}>
                    Sensorial
                  </span>
                </Grid>
                <Grid item lg={2} sm={4}>
                  <span className="nav_item" onClick={navClick}>
                    Language
                  </span>
                </Grid>
                <Grid item lg={2} sm={4}>
                  <span className="nav_item" onClick={navClick}>
                    Maths
                  </span>
                </Grid>
                <Grid item lg={2} sm={4}>
                  <span className="nav_item" onClick={navClick} variant="body2">
                    Culture
                  </span>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                mx: 2,
                my: 4,
                p: 3,
                bgcolor: "white",
                boxShadow: "0 0 5px 0 #888888",
              }}
            >
              <Grid container>
                <div className="mb-2" style={{ fontweight: "500px" }}>
                  Upload Bulk assessment
                </div>
                <Grid
                  item
                  md={12}
                  sx={{
                    width: "100%",
                    height: "60px",
                    px: 2,
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    border: "2px dashed grey",
                    backgroundSize: "10px 3px",
                  }}
                >
                  <input
                    type="file"
                    id="actual-btn_excel"
                    hidden
                    className="excel_btn_btn"
                    onChange={inpChange}
                  />

                  <label
                    htmlFor="actual-btn_excel"
                    className="w-100 text-center"
                    role="button"
                    style={{ textDecoration: "underline", color: "#1799cd" }}
                  >
                    Choose file
                  </label>
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mt={2}
                >
                  <span style={{ color: "grey" }}>{selectedFile}</span>
                  <Button className="btn_primary" onClick={uploadExcel}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {filteredAssesment.length == 0 ? (
              // <Typography>No Assesment Added....</Typography>
              <Grid
                container
                sx={{ display: "grid", placeItems: "center", height: "40vh" }}
              >
                <Box
                  sx={{
                    width: "30%",
                    height: "200px",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <img
                    src="images/noData.png"
                    alt=""
                    style={{ width: "150px" }}
                  />
                  <Typography sx={{ color: "gray" }}>No Data Found</Typography>
                </Box>
              </Grid>
            ) : (

              <Box sx={{ mx: 2 }}>
                {filteredAssesment.map((res, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        p: 4,
                        mb: 3,
                        bgcolor: "white",
                        boxShadow: "0 0 5px 0 #888888",
                      }}
                    >
                      <Grid container>
                        <Grid item md={6}>
                          <Typography
                            variant="h5"
                            sx={{ mb: 1, textTransform: "capitalize" }}
                          >
                            {res.section_name}
                          </Typography>
                          <Typography
                            sx={{
                              color: "grey",
                              fontSize: "17px",
                              textTransform: "capitalize",
                            }}
                          >
                            {res.assesment_name}:
                          </Typography>
                        </Grid>

                        <Grid item md={6} sx={{ textAlign: "end" }}>
                          <Link to={`/editConfiguration/${res._id}`}>
                            <Edit
                              sx={{
                                color: "#169AC9",
                                cursor: "pointer",
                                mx: 1,
                              }}
                            />
                          </Link>
                          {/* <Delete
                            sx={{ cursor: "pointer", color: "#169AC9" }}
                            onClick={() => {
                              setOpenDelete(true);
                              setDeletedIndex({ index, id: res._id });
                            }}
                          /> */}
                          <DeleteAssessment id={res._id} index={index} deleteAssesment={deleteAssesment} />

                          {/* <Dialog open={openDelete}>
                            <DialogTitle id="alert-dialog-title">
                              Confirm Delete
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Are You Sure You Want To Delete Assessment?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={() => setOpenDelete(false)}
                                sx={{ color: "#3FAEED" }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={() => deleteAssesment()}
                                sx={{ color: "red" }}
                              >
                                Delete
                              </Button>
                            </DialogActions>
                          </Dialog> */}
                        </Grid>
                      </Grid>

                      <Grid container>
                        {res.assesmentActivity.map((act, index2) => {
                          return (
                            <Grid key={index2} item md={3} sx={{ my: 1 }}>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked
                                  onChange={() => { }}
                                />
                                <Typography
                                  sx={{
                                    mx: 1,
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {act}
                                </Typography>
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>

                      <Typography sx={{ color: "grey", fontSize: "17px" }}>
                        Assessment Applied on:
                      </Typography>
                      <Grid container>
                        {res.ageGroup.map((res2, index3) => {
                          return (
                            <Grid key={index3} item md={3} sx={{ my: 1 }}>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked
                                  onChange={() => { }}
                                />
                                <Typography
                                  sx={{
                                    mx: 1,
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {res2} year old
                                </Typography>
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>

                      <Typography sx={{ color: "grey", fontSize: "17px" }}>
                        Educator can comment:
                      </Typography>
                      <Grid container>
                        <Grid item md={3} sx={{ my: 1 }}>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "14px",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={res.enable_educator_to_comment}
                              onChange={() => { }}
                            />
                            <Typography variant="subtitle1" sx={{ mx: 1 }}>
                              {res.enable_educator_to_comment
                                ? "Enabled"
                                : "Disabled"}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Typography sx={{ color: "grey", fontSize: "17px" }}>
                        Activity Reference:
                      </Typography>
                      <Grid container>
                        <Grid item md={3} sx={{ my: 1 }}>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "14px",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={res.activity_reference}
                              onChange={() => { }}
                            />
                            <Typography variant="subtitle1" sx={{ mx: 1 }}>
                              {res.activity_reference ? "Enabled" : "Disabled"}
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>

                      <span style={{ color: "grey", fontSize: "17px" }}>
                        Goal :
                      </span>
                      <span> {res.goal_name !== undefined ? res.goal_name : ''}</span>




                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>
      )}
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
    </Sidebar>
  );
};
