import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCscDO1m88VMsGAY5WwADOq8e_3uwpigxI",
  authDomain: "childcare-f2d85.firebaseapp.com",
  databaseURL: "https://childcare-f2d85-default-rtdb.firebaseio.com",
  projectId: "childcare-f2d85",
  storageBucket: "childcare-f2d85.appspot.com",
  messagingSenderId: "447679795796",
  appId: "1:447679795796:web:ae07b0132d99bdc34093c1",
  measurementId: "G-TVEREECTM9"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
export { db };
export default storage;
