import { Typography, Grid, Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getLatestAge } from "../getLatestAge";

const Parentview = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [childs, setChilds] = useState([]);
  const [childData, setchildData] = useState([]);
  const [loader, setLoader] = useState(true);

  console.log(childs.length);
  console.log(childData);
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  const getData = () => {
    axios
      .get(`${baseURL}parent/getParent/${id}`)
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        setLoader(false)
        setChilds(res.data.data.child_array.length);
        setchildData(res.data.data.Child_data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Sidebar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid px={3}>
          <Link to="/parentdetails">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        <Typography align="center" sx={{ fontSize: "30px", mb: 2 }}>
          Parents Details
        </Typography>
        <Grid sx={{ mx: 5 }}>
          <Grid sx={{ backgroundColor: "white", padding: "30px" }}>
            <Grid container>
              <Grid item></Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Father Name :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.father_name} {data.father_lastname}
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Mother Name :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.mother_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Phone Number :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.parent_phone_number}
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Alternative Number :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.parent_alternative_phone_number}
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Number of Child :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {/* {data.child_array.length} */}
                  {childs}
                </Typography>
              </Grid>
            </Grid>

            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Father's Occupation :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.father_occupation}
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Mother's Occupation :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.mother_occupation}
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Email :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.parent_email}
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Home Address :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.parent_home_address}
                </Typography>
              </Grid>
            </Grid>
            <Grid container pt={2}>
              <Grid item md={4}>
                <Typography>Work Address :</Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ color: "#1799CE" }}>
                  {data.parent_work_address}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {childData.map((item, index) => {
            return (
              <>
                <Typography align="center" sx={{ fontSize: "20px", my: 2 }}>
                  Child {index + 1} Details
                </Typography>
                <Grid
                  key={index}
                  sx={{ backgroundColor: "white", padding: "30px" }}
                >
                  <Grid container pt={2}>
                    <Grid item md={2}>
                      <Typography>Name :</Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ color: "#1799CE" }}>
                        {item.child_name} {item.child_lastname}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container pt={2}>
                    <Grid item md={2}>
                      <Typography>Gender :</Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ color: "#1799CE" }}>
                        {item.child_gender}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container pt={2}>
                    <Grid item md={2}>
                      <Typography>Age :</Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ color: "#1799CE" }}>
                        {getLatestAge(item.child_dob)} years
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container pt={2}>
                    <Grid item md={2}>
                      <Typography>DOB :</Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ color: "#1799CE" }}>
                        {item.child_dob}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Sidebar>
    </>
  );
};

export default Parentview;
