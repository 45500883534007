import { Typography, Grid, Backdrop, CircularProgress, Box } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getLatestAge } from "../getLatestAge";

const Teacherview = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  let [loader, setLoader] = useState(true);
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  const getData = () => {
    axios
      .get(`${baseURL}teacher/getTeacher/${id}`)
      .then((res) => {
        setData(res.data.data[0]);
        console.log(res.data.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Sidebar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid px={3}>
          <Link to="/educators">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        {!loader ?
          <Grid sx={{ backgroundColor: "#EFF6FF", padding: "30px" }}>
            <Typography align="center" sx={{ fontSize: "30px", mb: 2 }}>
              Educator Details
            </Typography>
            <Grid sx={{ backgroundColor: "white", padding: "30px" }}>
              <Grid container>
                <Grid item>


                </Grid>
                <Grid item pt={1} pl={2}></Grid>
              </Grid>

              <Grid container pt={2}>
                <Grid item md={4}>
                  <Typography>Name :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {data.teacher_name} {data.teacher_lastname}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={4}>
                  <Typography>Gender :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {data.teacher_gender}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={4}>
                  <Typography>Age :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {getLatestAge(data.teacher_dob)} years
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={4}>
                  <Typography>DOB :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {data.teacher_dob}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={4}>
                  <Typography>Email :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {data.teacher_email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={4}>
                  <Typography>Phone Number :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {data.teacher_number}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={4}>
                  <Typography>Qualification :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {data.teacher_qualification}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={4}>
                  <Typography>Address :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {data.teacher_address}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid> : null}


      </Sidebar>
    </>
  );
};

export default Teacherview;
