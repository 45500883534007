import {
  Add,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Remove,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL } from "../../auth/GlobalUrl";

export const CompletedAssesment = ({
  id,
  completeAssesmentDate,
  setPdfData,
}) => {
  let [assesmentData, setAssesmentData] = useState({});
  // console.log(assesmentData)
  let [lineProgress, setLineProgress] = useState(true);

  let [learningArea, setLearningArea] = useState("Practical");

  let [noData, setNoData] = useState(false);
  function navClick(d) {
    let btn = document.getElementsByClassName("nav_item");
    let i = 0;
    for (i = 0; i < btn.length; i++) {
      btn[i].style.color = "black";
      btn[i].style.borderBottom = "none";
    }
    d.target.style.color = "#FA981D";
    d.target.style.borderBottom = "1px solid #FA981D";
    // console.log(d.target.textContent);
    setLineProgress(true);
    getCompletedAssesmentByDate(completeAssesmentDate, d.target.textContent);
    setLearningArea(d.target.textContent);
  }
  useEffect(() => {
    getCompletedAssesmentByDate(completeAssesmentDate, learningArea);
  }, [completeAssesmentDate]);

  function getCompletedAssesmentByDate(date, learning_area) {
    setLineProgress(true);
    // console.log(date);
    // console.log(learning_area);

    let url = `${baseURL}child/getAssessmentByDate/${id}`;
    axios
      .put(url, { date, learning_area })
      .then((res) => {
        // console.log(res.data,'{{{{{{{{{{{{{{{{{{{{{{{{{{{{{{}}}}}}}}}}}}}}}}}}}}}}}}}}}}');

        if (res.data.data !== undefined) {
          setPdfData(res.data.data);
          setAssesmentData(res.data.data);
          setLineProgress(false);
          setNoData(false);
          // getAss(res.data.data);

          if (Object.keys(res.data.data).length == 0) {
            setNoData(true);
          }
        } else {
          setAssesmentData({});
          setNoData(true);
          setLineProgress(false);
        }
      })
      .catch((err) => console.log(err));
  }

  function DropDownData({ assData, Assmentres }) {
    console.log(assData);
    console.log(Assmentres);

    let [dropDown, setDropDown] = useState(false);
    const [newData, setNewData] = useState([]);

    useEffect(() => {
      let arr = [];
      console.log(assData, "as as as aas as as as as as as as");

      assData.forEach((res) => {
        let ind = arr.findIndex(
          (res2) => res2.assessment_name == res.assessment_name
        );
        // console.log(ind)
        if (ind == -1) {
          // console.log(res,'sumit')
          let assessment_name = res.assessment_name;
          // delete res.assessment_name

          arr.push({
            assessment_name: assessment_name,
            data: [{ ...res }],
            subsection_name: res.assessment_subsection_name,
          });
        } else {
          // console.log(res,'varun')
          // delete res.assessment_name
          arr[ind].data.push(res);
        }
      });
      console.log(arr);
      setNewData(arr);
    }, []);

    function FilteresBySubsection({ section, data }) {
      // let subsection = data.subsection_name
      // let assessment = data.assessment_name
      // console.log(data, "{}{}{}{}{}{}{")
      // // console.log(assData)
      // let filteredData = assData.filter((re) => (
      //   re.assessment_section_name = assessment && re.assessment_subsection_name == subsection
      // ))
      // console.log(filteredData)
      return (
        <>
          {data.data.map((res, index) => (
            <>
              <Typography sx={{ color: "grey", textTransform: "capitalize" }}>
                {res.assessment_subsection_name}
              </Typography>

              <Grid
                container
                key={index}
                sx={{ alignItems: "center", justifyContent: "center", mb: 1 }}
              >
                <Grid item md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {res.invigilator_name ? res.invigilator_name : ""}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography
                    variant="subtitle1"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {res.assessment_activity[0]
                      ? res.assessment_activity[0]
                      : "----"}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography sx={{ fontSize: "12px", px: 3 }}>
                    <div>Date: {res.created_at}</div>
                    <div>Time: {res.time}</div>
                  </Typography>
                </Grid>

                <Grid item md={3}>
                  {res.comment.trim() !== "" ? (
                    <div className="w-100 overflow-auto px-2 py-1 border rounded text-secondary">
                      {res.comment}
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          ))}
        </>
      );
    }

    // assData.map((res) => console.log(res));
    return (
      <>
        <Grid
          item
          md={!dropDown ? 6 : 12}
          onClick={() => setDropDown(!dropDown)}
          className="pointer"
          sx={{ fontSize: "18px", textTransform: "capitalize" }}
        >
          <b>{Assmentres}</b>
        </Grid>

        <Grid item md={6} align="right">
          {!dropDown ? (
            <Typography
              onClick={() => setDropDown(!dropDown)}
              sx={{ cursor: "pointer" }}
            >
              <Add />
            </Typography>
          ) : null}
        </Grid>
        {dropDown ? (
          <Container>
            <Container sx={{ mt: 2 }}>
              {newData.map((e, ind) => {
                return (
                  <>
                    <Typography
                      sx={{ color: "#1799CE", textTransform: "capitalize" }}
                    >
                      {e.assessment_name}
                    </Typography>
                    {/* <Typography sx={{ color: 'grey', textTransform: 'capitalize' }}> */}

                    {/* {e.subsection_name} */}
                    {/* </Typography> */}
                    <FilteresBySubsection section={Assmentres} data={e} />

                    <hr />
                  </>
                );
              })}
            </Container>
            <Typography
              onClick={() => setDropDown(false)}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Remove />
            </Typography>
          </Container>
        ) : null}
      </>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          bgcolor: "#FCFCFC",
          p: 2,
          mt: 1,
          boxShadow: "0 0 5px 0 #888888",
        }}
        className="assessment_box"
      >
        <Grid item md={2}>
          <span className="nav_item active" onClick={navClick}>
            Practical
          </span>
        </Grid>
        <Grid item md={2}>
          <span className="nav_item" onClick={navClick}>
            Sensorial
          </span>
        </Grid>
        <Grid item md={2}>
          <span className="nav_item" onClick={navClick}>
            Language
          </span>
        </Grid>
        <Grid item md={2}>
          <span className="nav_item" onClick={navClick}>
            Maths
          </span>
        </Grid>
        <Grid item md={2}>
          <span className="nav_item" onClick={navClick} variant="body2">
            Culture
          </span>
        </Grid>
      </Grid>

      {lineProgress ? (
        <Box mt={3}>
          <LinearProgress />
        </Box>
      ) : (
        <Box
          sx={{
            mt: 2,
          }}
        >
          {noData ? (
            <Container>No data available...</Container>
          ) : (
            <Box>
              <Container
                sx={{
                  mt: 2,
                  mx: 0,
                  bgcolor: "white",
                  boxShadow: "0 0 5px 0 #888888",
                }}
              >
                {Object.keys(assesmentData).map((res, index) => {
                  return (
                    <Grid
                      key={index}
                      container
                      sx={{ py: 2, borderBottom: "1px solid grey" }}
                    >
                      {/* <Grid item md={6}>
                        <b>{res}</b>
                      </Grid> */}
                      <DropDownData
                        Assmentres={res}
                        assData={assesmentData[res]}
                      />
                    </Grid>
                  );
                })}
              </Container>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
