import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Sidebar } from "../../components/Sidebar";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Popper,
  TextField,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { SnackBar } from "../SnackBar";
import { AddRoom } from "./AddRoom";
import { ResetPassword } from "./ResetPassword";
import { DeleteConfirmComp } from "../../components/DeleteConfirmComp";

export default function Rooms() {
  const navigate = useNavigate();
  let [loader, setLoader] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  let [allRooms, setAllRooms] = useState([]);
  let tenant_id = localStorage.getItem("tenant_id");

  let [editRoom, setEditRoom] = useState({ room_name: "", room_id: "" });
  let [all, setAll] = useState([]);

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  React.useEffect(() => {
    getAllRooms();
  }, []);

  function getAllRooms() {
    setLoader(true);
    let url = `${baseURL}room/getAll/${tenant_id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        setAllRooms(res.data.data);
        setAll(res.data.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }
  const handleClick2 = (event, room_id, room_name) => {
    room_id !== undefined ? setEditRoom({ room_id, room_name }) : null;
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  function EditRoom() {
    setLoader(true);
    console.log(editRoom);
    handleClick2();

    let url = `${baseURL}room/updateRoom/${editRoom.room_id}`;
    axios
      .put(url, { room_name: editRoom.room_name })
      .then((res) => {
        setLoader(false);
        getAllRooms();
        console.log(res);
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: "Room updated successfully",
          type: "success",
        });
      })
      .catch((err) => console.log(err));
  }

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popper" : undefined;



  function onSearch(e) {
    let { value } = e.target;
    value == " " ? (value = "") : null;
    setAllRooms(
      all.filter((r) => r.room_name.toLowerCase().includes(value.toLowerCase()))
    );
  }


  async function resetRoomPassword(credData, roomId) {
    setLoader(true)
    try {
      let res = await axios.put(Apis.ROOM.update + roomId, { room_password: credData.password })
      console.log(res)
      setLoader(false)
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Room password Updated successfully",
        type: "success",
      });
    }
    catch (err) {
      setLoader(false)
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Some error Occured",
        type: "error",
      });
      console.log(err)
    }
  }





  async function deleteRoom(roomId) {

    try {
      setLoader(true);
      let res = await axios.delete(Apis.ROOM.delete + roomId)
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Room deleted successfully",
        type: "error",
      });
      getAllRooms();
      setLoader(false);
    }
    catch (err) {
      console.log(err)
      setLoader(false);
    }
  }

  return (
    <Sidebar>
      <Grid container paddingX={2}>
        <Grid container py={2}>
          <Grid item md={5}>
            <TextField
              onChange={onSearch}
              className="searchInput"
              sx={{ bgcolor: "white" }}
              placeholder="Search Room Name.."
            />
          </Grid>
          <Grid
            item
            md={7}
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item></Grid>
            <Grid item>
              <AddRoom getAllRooms={getAllRooms} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid px={2} mt={2}>
        <Table sx={{ bgcolor: "white" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Room Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="left">Number of Child</TableCell>
              <TableCell align="left">Number of Educator</TableCell>
              <TableCell align="left">Action</TableCell>
              <TableCell align="left">Credentials</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allRooms.map((res, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {res.room_name}
                </TableCell>
                <TableCell align="left">{res.room_email}</TableCell>
                <TableCell align="left">{res.room_childArray.length}</TableCell>
                <TableCell align="left">
                  {res.room_teacherArray.length}
                </TableCell>
                <TableCell align="left">
                  <RemoveRedEyeIcon
                    sx={{ color: "orange", cursor: "pointer" }}
                    onClick={() => navigate(`/roomview/${res._id}`)}
                  />
                  <BorderColorOutlinedIcon
                    color="info"
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => handleClick2(e, res._id, res.room_name)}
                  />
                  <Popper id={id2} open={open2} anchorEl={anchorEl2}>
                    <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          EditRoom(res._id);
                        }}
                      >
                        <TextField
                          required
                          id="outlined-basic"
                          placeholder="Enter Room Name"
                          variant="outlined"
                          defaultValue={editRoom.room_name}
                          onChange={(e) =>
                            e.target.value == " "
                              ? (e.target.value = "")
                              : setEditRoom({
                                ...editRoom,
                                room_name: e.target.value,
                              })
                          }
                        />
                        <Grid
                          pt={2}
                          sx={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              textTransform: "none",
                              border: "1px solid red",
                              color: "red",
                              background: "white",
                            }}
                            onClick={handleClick2}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;&nbsp;
                          <Button
                            variant="contained"
                            color="info"
                            size="small"
                            sx={{ textTransform: "none" }}
                            type="submit"
                          >
                            Add{" "}
                          </Button>
                        </Grid>
                      </form>
                    </Box>
                  </Popper>

                  <DeleteConfirmComp msg='Are your want to delete Room ?' id={res._id} deleteFun={deleteRoom} />

                </TableCell>
                <TableCell>
                  <ResetPassword
                    roomId={res._id}
                    resetPasswordSubmit={resetRoomPassword}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Sidebar>
  );
}
