import { Typography, Grid } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { useParams } from "react-router-dom";
import { baseURL } from "../baseURL";
import axios from "axios";

export default function Starter() {
  const [childData, setChildData] = useState([]);
  const [loader, setLoader] = useState(true);

  let [filteredRecord,setFilteredRecord]=useState([])

  let dat = new Date();
  let yr = dat.getFullYear();
  let mnt = dat.getMonth();
  let day = dat.getDate();
  let fullDate = `${yr}-${mnt + 1 < 10 ? "0" : ""}${mnt + 1}-${
    day < 10 ? "0" : ""
  }${day}`;


  let { id } = useParams();

  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  function getAllRecordChid() {
    let url = `${baseURL}child/getChild/${id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data[0]);
        setChildData(res.data.data[0]);
        setFilteredRecord(res.data.data[0].child_records.filter(r=>r.created_at==fullDate))
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getAllRecordChid();
  }, []);
  function filterRecord(e){
let {value}=e.target
console.log(value)
if(value==''){
  setFilteredRecord(childData.child_records)
}
else{
  setFilteredRecord(childData.child_records.filter((res)=>res.created_at==value))
}

  }

  return (
    <Sidebar>
      <Grid p={6} sx={{ backgroundColor: "#EFF6FF" }}>
        {!loader ? (
          <Grid sx={{ border: "2px solid gray", backgroundColor: "white" }}>
            <Grid
              container
              p={3}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Typography>
                      {childData.child_profileUrl !== undefined ? (
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50px",
                          }}
                          className="mx-3"
                          src={childData.child_profileUrl}
                        />
                      ) : (
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50px",
                          }}
                          className="mx-3"
                          src={blankImage}
                        />
                      )}
                      {childData.child_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container sx={{ color: "#3FAEED" }}>
                  <Grid item>
                    <Typography>
                      <input type="date" onChange={filterRecord}/>
                    </Typography>
                  </Grid>
                 
                </Grid>
              </Grid>
            </Grid>
            <hr />

            <Grid
              container
              p={3}
              spacing={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {filteredRecord.map((res, index) => {
                return (
                  <Grid item md={6} key={index}>
                    <Grid container>
                      <Grid item sx={{ paddingTop: "6px", color: "#5B940E" }}>
                        <PanoramaFishEyeIcon />
                      </Grid>
                      <Grid item pl={2}>
                        <Typography sx={{ color: "#5B940E" }}>
                          {res.record_name}
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {res.time} {res.educator_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Sidebar>
  );
}
