import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  List,
  Drawer,
  AppBar,
  Toolbar,
  ListItem,
  Typography,
  ListItemButton,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Close,
  FirstPage,
  Home,
  KeyboardArrowDown,
  Person,
  Plagiarism,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useEffect, useState } from "react";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import ArticleIcon from "@mui/icons-material/Article";
import { useContext } from "react";
import { contextData } from "../Router";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import AirlineSeatFlatIcon from "@mui/icons-material/AirlineSeatFlat";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import WcIcon from "@mui/icons-material/Wc";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPinStatus } from "../Redux-toolkit/Slices/EducatorPinSlice";
import logo from "./logo.png";
import { io } from "socket.io-client";
import { changeCenter } from "../Redux-toolkit/Slices/ChildCareCenters";

export const Sidebar = ({ children }) => {
  // console.log(id)

  const matches = useMediaQuery("(min-width:1130px)");
  const eduId = localStorage.getItem("id");

  const nav = useContext(contextData);
  const parentId = localStorage.getItem("parentId");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  const role = localStorage.getItem("role");
  let nav_name = localStorage.getItem("nav_name");
  // const [activeNav,setactiveNav] = useState('')
  let allCenters = useSelector((e) => e.Childcare_centers);
  function activeNavigation(e) {
    //   console.log(e.target.textContent)
    nav.setNav(e.target.textContent);
    localStorage.setItem("nav_name", e.target.textContent);
  }
  if (role === "Room") {
    var socket = io("https://childcare-714af9baa16a.herokuapp.com", {
      auth: {
        eduId,
      },
    });
  }
  const navigateTo = () => {
    if (role === "SuperAdmin") {
      localStorage.setItem("nav_name", "Admin");
      navigate("/adminlisting");
    } else if (role === "Educator") {
      localStorage.setItem("nav_name", "Configuration");
      navigate(`/assessment/${eduId}`);
    } else if (role === "Parent") {
      localStorage.setItem("nav_name", "Configuration");
      navigate(`/parent/${parentId}`);
    } else if (role === "Admin") {
      localStorage.setItem("nav_name", "Home");
      navigate("/home");
    }
  };
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  useEffect(() => {
    console.log(matches);
  }, []);
  let dispatch = useDispatch();
  let currentCenter = useSelector((e) => e.Childcare_centers);

  async function logout() {
    dispatch(setPinStatus(false));
    if (role === "Room") {
      socket.emit("logout", { id: eduId });
    }
    localStorage.clear();
    navigate("/");
  }
  let userLogo = localStorage.getItem("logo_url");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function centerChange(dd) {
    console.log(dd);
    dispatch(changeCenter(dd));
    localStorage.setItem("ChildcareCenterData", JSON.stringify(dd));
    localStorage.setItem("tenant_id", dd.tenant_id);
    localStorage.setItem("logo_url", dd.logo_url);
    localStorage.setItem("childcare_center", dd.childcare_center);
    navigate("/home");
    localStorage.setItem("nav_name", "Home");
    window.location.reload();
  }

  return (
    <Box sx={{ display: "flex", bgcolor: "#EFF6FF", width: "100%" }}>
      {/* <AppBar position="fixed" sx={{ width: "calc(100% - 250px)" }}> */}
      <AppBar position="fixed" sx={{ width: "100%", zIndex: "3" }}>
        <Toolbar>
          {!matches ? (
            <>
              <IconButton onClick={() => setActive(!active)} sx={{ mr: 3 }}>
                {active ? <Close /> : <MenuIcon />}
              </IconButton>
            </>
          ) : null}
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <Typography sx={{ flexGrow: 1, ml: matches ? 30 : 0 }}>
                {nav_name}
              </Typography>
            </div>

            {localStorage.getItem("role") == "Admin" ? (
              <div>
                <Typography
                  onClick={handleClick}
                  className="px-3 py-1 pointer bg-white"
                  sx={{ border: "1px solid black", borderRadius: "4px" }}
                >
                  {currentCenter?.currentCenter.child_care_center_name}
                  <span className="ms-4">
                    <KeyboardArrowDown />
                  </span>
                </Typography>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {allCenters.allcenters.map((res) => {
                    return (
                      <MenuItem
                        className="bg-white"
                        onClick={() => {
                          setAnchorEl(null);
                          centerChange(res);
                        }}
                        sx={{
                          backgroundColor:
                            res.child_care_center_name ==
                            currentCenter?.currentCenter.child_care_center_name
                              ? "rgb(200, 199, 199) !important"
                              : "white !important",
                        }}
                      >
                        {res.child_care_center_name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        open={active}
        variant={!matches ? "temporary" : "permanent"}
        anchor="left"
        onClose={() => setActive(false)}
        sx={{
          height: "100%",
          zIndex: "9",
          width: 250,
          "& .MuiDrawer-paper": {
            width: 250,
          },
        }}
      >
        <img
          onClick={navigateTo}
          className="mx-auto mt-1"
          style={{ cursor: "pointer", width: "95% " }}
          src={userLogo !== "" ? userLogo : logo}
          alt="#Logo"
        />
        {localStorage.getItem("role") == "Room" ? (
          <>
            <span onClick={activeNavigation}>
              <FullListItem
                icon={<ArticleIcon />}
                text="Assessment"
                link={`/assessment/${eduId}`}
              />
            </span>
            <span onClick={activeNavigation}>
              <FullListItem
                icon={<ReceiptLongIcon />}
                text="Routines"
                link="/routines"
              />
            </span>
            <Typography
              onClick={() => setShow((prev) => !prev)}
              sx={{
                cursor: "pointer",
                padding: "15px 8px",
                borderBottom: "1px solid lightgray",
                margin: "0 25px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                <ReceiptLongIcon /> Reports
              </span>{" "}
              {show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Typography>

            {show ? (
              <Box sx={{ padding: "0 0 0 20px" }}>
                <span onClick={activeNavigation}>
                  <FullListItem
                    icon={<Plagiarism />}
                    text="Accident"
                    link="/accident"
                  />
                </span>
                <span onClick={activeNavigation}>
                  <FullListItem
                    icon={<Plagiarism />}
                    text="Illness"
                    link="/illness"
                  />
                </span>
                <span onClick={activeNavigation}>
                  <FullListItem
                    icon={<Plagiarism />}
                    text="Injury"
                    link="/injury"
                  />
                </span>
                <span onClick={activeNavigation}>
                  <FullListItem
                    icon={<Plagiarism />}
                    text="Injury on Intake"
                    link="/injuryonintake"
                  />
                </span>
              </Box>
            ) : (
              ""
            )}
          </>
        ) : (
          <></>
        )}
        {localStorage.getItem("role") == "Parent" ? (
          <>
            <span onClick={activeNavigation}>
              <FullListItem
                icon={<ArticleIcon />}
                text="Observation"
                link={`/parent/${parentId}`}
              />
            </span>
            <span onClick={activeNavigation}>
              <FullListItem
                icon={<ReceiptLongIcon />}
                text="Routines"
                link="/childrecords"
              />
            </span>
          </>
        ) : (
          <></>
        )}
        {localStorage.getItem("role") == "Admin" ? (
          <>
            <span onClick={activeNavigation}>
              <FullListItem icon={<Home />} text="Home" link="/home" />
            </span>
            <span onClick={activeNavigation}>
              <FullListItem
                icon={<ArticleIcon />}
                text="Assessment"
                link="/configuration"
              />
            </span>
            <span onClick={activeNavigation}>
              <FullListItem
                icon={<TransferWithinAStationIcon />}
                text="Educators"
                link="/educators"
              />
            </span>
            <span onClick={activeNavigation}>
              <FullListItem
                icon={<FamilyRestroomIcon />}
                text="Parents"
                link="/parentdetails"
              />
            </span>
            <span onClick={activeNavigation}>
              <FullListItem
                icon={<WcIcon />}
                text="Children"
                link="/childlisting"
              />
            </span>

            <span onClick={activeNavigation}>
              <FullListItem
                onClick={activeNavigation}
                icon={<AirlineSeatFlatIcon />}
                text="Rooms"
                link="/rooms"
              />
            </span>

            <span onClick={activeNavigation}>
              <FullListItem
                onClick={activeNavigation}
                icon={<ReceiptLongIcon />}
                text="Routines"
                link="/records"
              />
            </span>

            {/* <FullListItem icon={<Plagiarism />} text="Starter" link="/starter" /> */}
          </>
        ) : (
          <></>
        )}
        {localStorage.getItem("role") == "SuperAdmin" ? (
          <>
            <span onClick={activeNavigation}>
              <FullListItem icon={<Home />} text="Admin" link="/adminlisting" />
            </span>
            <span onClick={activeNavigation}>
              <FullListItem icon={<Home />} text="Center" link="/AllCenters" />
            </span>
          </>
        ) : (
          <></>
        )}
        <List></List>
        <Box px={3} sx={{ position: "absolute", bottom: "20px" }}>
          <div onClick={logout} style={{ cursor: "pointer" }}>
            <b>
              <LogoutSharpIcon sx={{ color: "red" }} />{" "}
              <span style={{ color: "gray" }}>Logout</span>
            </b>
          </div>
        </Box>
      </Drawer>
      <Box sx={{ pt: 10, mb: 10, width: "100%", minHeight: "87vh" }}>
        {children}
      </Box>
    </Box>
  );
};

export const FullListItem = ({ text, icon, link }) => {
  const navigate = useNavigate();

  return (
    <>
      <ListItem>
        <ListItemButton onClick={() => navigate(`${link}`)}>
          <Typography>{icon}</Typography>
          <Typography sx={{ fontSize: "15px", ml: 1 }}>
            <b>{text}</b>
          </Typography>
        </ListItemButton>
      </ListItem>
      <Typography
        sx={{ borderBottom: "1px solid #CCCCCC", mx: 3 }}
      ></Typography>
    </>
  );
};
