import { Button, Card, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography, checkboxClasses } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box } from "@mui/system";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Style from "./Style.css";
import axios from "axios";
import { Apis } from "../../auth/GlobalUrl";


export const EditAddAdminDesign = ({ admindata, submitFun, mode }) => {
  let navigate = useNavigate();

  const [adminData, setAdminData] = useState(admindata);
  const [showPass, setShowPass] = useState(false);
  const [allCenters, setAllCenters] = useState([])
  console.log(admindata)
  function addAdmin(e) {
    e.preventDefault();
    submitFun(adminData)
  }

  async function getAllCenters() {
    try {
      let res = await axios.get(Apis.SUPERADMIN.getAllChildCareCenters)
      setAllCenters(res.data.data)
      console.log(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    getAllCenters()
  }, [])

  return (
    <>
      <Grid px={3}>
        <Link to='/adminlisting'>
          <ArrowBackIosIcon />
        </Link>
      </Grid>
      <Typography variant="h4" align="center" my={1}>
        {mode == 'editAdmin' ? 'Edit Admin' : 'Add Admin'}
      </Typography>
      <form onSubmit={addAdmin}>
        <Grid item md={11} sm={11} xs={11} mx={5}>
          <Card
            variant="outlined"
            className="w-100"
            sx={{ background: "#FFFFFF", boxShadow: "0 0 5px 0 #888888", py: 2 }}
          >
            <Grid container className='grid_item' >
              <Grid item md={6} sm={12} xs={12}>
                <Grid container my={1}>
                  <Grid item md={8} sm={8} xs={8} py={1}>
                    <Typography>Admin Name</Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <TextField
                      variant="outlined"
                      placeholder="Enter Admin Name"
                      className="w-100"
                      required
                      value={adminData.admin_name}
                      sx={{ background: "#ffffff" }}
                      onChange={(e) =>
                        e.target.value == " " ? e.target.value = '' : setAdminData({
                          ...adminData,
                          admin_name: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Grid container my={1}>
                  <Grid item md={8} sm={8} xs={8} py={1}>
                    <Typography>Phone Number</Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <input

                      variant="outlined"
                      required
                      name="parent_phone_number"
                      type="number"
                      min='0'
                      autoComplete='off'
                      placeholder="Enter Number"
                      value={adminData.admin_contact}
                      onChange={(e) =>
                        e.target.value == " " ? e.target.value = '' : setAdminData({
                          ...adminData,
                          admin_contact: e.target.value,
                        })
                      }
                      className="w-100 form-control py-3"
                      sx={{ background: "#ffffff" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='grid_item' pb={3}>
              <Grid item md={6} sm={12} xs={12}>
                <Grid container >
                  <Grid item md={8} sm={8} xs={8} py={1}>
                    <Typography>Address</Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <TextField
                      required
                      fullWidth
                      placeholder="Enter Address"
                      value={adminData.admin_address}

                      onChange={(e) =>
                        e.target.value == " " ? e.target.value = '' : setAdminData({
                          ...adminData,
                          admin_address: e.target.value,
                        })
                      }
                      sx={{ background: "#ffffff" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item md={6} sm={12} xs={12}>
                <Grid container >
                  <Grid item md={8} sm={8} xs={8} py={1}>
                    <Typography>Child Care Center Name</Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <TextField
                      required
                      placeholder="Enter Center Name"
                      className="w-100"
                      value={adminData.child_care_center}

                      onChange={(e) =>
                        e.target.value == " " ? e.target.value = '' : setAdminData({
                          ...adminData,
                          child_care_center: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item md={6} sm={12} xs={12}>
                <Grid container >
                  <Grid item md={8} sm={8} xs={8} py={1}>
                    <Typography>Email</Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <TextField
                      required
                      placeholder="Enter Center Name"
                      className="w-100"
                      value={adminData.email}
                      disabled={mode == 'editAdmin'}
                      onChange={(e) =>
                        e.target.value == " " ? e.target.value = '' : setAdminData({
                          ...adminData,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='grid_item' pb={3}>
              <Grid item md={6} sm={12} xs={12}>
                <Grid container >
                  <Grid item md={8} sm={8} xs={8} py={1}>
                    <Typography>Select Centers</Typography>
                  </Grid>
                  <Grid item md={8} sm={8} xs={8}>
                    <FormControl className="w-100">
                      <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                      <Select required={mode == 'editAdmin'} fullWidth
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={adminData.child_center}
                        name='admin_array'
                        onChange={(e) =>
                          setAdminData({
                            ...adminData,
                            child_center: e.target.value,
                          })
                        }
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => allCenters.filter(e => adminData.child_center.indexOf(e._id) !== -1).map(e => e.child_care_center_name).join(',')}
                      >
                        {allCenters.map(res => (
                          <MenuItem key={res.child_care_center_name} value={res._id}>
                            <Checkbox
                              sx={{
                                [`&, &.${checkboxClasses.checked}`]: {
                                  color: 'blue',
                                },
                              }}
                              checked={adminData.child_center.indexOf(res._id) > -1} />
                            <ListItemText primary={res.child_care_center_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>


          </Card>

        </Grid >

        <Grid container px={5}>
          <Grid
            item
            mt={2}
            md={12}
            sm={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="other"
              sx={{ border: "1px solid #FF0000", color: "red" }}
              onClick={() => navigate("/adminlisting")}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="other"
              sx={{
                background: "#1799CD",
                color: "#ffffff",
                border: "1px solid #1799CD",
                marginLeft: "10px",
              }}
            >
              {mode == 'editAdmin' ? 'Save' : 'Add Admin'}
            </Button>
          </Grid>
        </Grid>
      </form >
    </>
  );
};
