import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import {
  Backdrop,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableContainer,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useContext } from "react";
import { contextData } from "../../Router";
import { SnackBar } from "../SnackBar";
import Paper from "@mui/material/Paper";


import { ResetPassword } from "../Rooms/ResetPassword";
import { DeleteConfirmComp } from "../../components/DeleteConfirmComp";

export const AdminListing = () => {
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  let [allAdmins, setAllAdmins] = useState([]);
  let [loader, setLoader] = useState(true);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [adminIdd, setAdminIdd] = useState("");
  console.log(adminIdd);
  let snackBarState = useContext(contextData);
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllAdmins();

    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    console.log(snackBarState.state);
    if (snackBarState.state) {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Admin details saved successfully",
        type: "success",
      });
      snackBarState.setState(false);
    }
  }, []);
  function getAllAdmins() {

    axios
      .get(Apis.SUPERADMIN.getAllAdmins)
      .then((res) => {
        console.log(res.data.data);
        setAllAdmins(res.data.data);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }

  function deleteAdmin(delId) {

    handleClose();
    setLoader(true);
    let url = `${baseURL}superadmin/deleteAdmin/${delId}`;
    axios
      .delete(url)
      .then((res) => {
        console.log(res);
        getAllAdmins();
        setTimeout(() => {
          setShow(true);
        }, 500);
        setTimeout(() => {
          setShow(false);
        }, 3500);
      })
      .catch((err) => console.log(err));
  }

  async function resetAdminPassword(credData, adminId) {
    setLoader(true)
    try {
      let res = await axios.put(Apis.ADMIN.changeCred + adminId, { password: credData.password })
      console.log(res)
      setLoader(false)
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Admin password Updated successfully",
        type: "success",
      });
    }
    catch (err) {
      setLoader(false)
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Some error Occured",
        type: "error",
      });
      console.log(err)
    }
  }



  return (
    <>
      <Sidebar>
        <div className="adminlisting">
          <Grid Container sx={{ display: "flex", justifyContent: "end" }}>
            <Grid
              item={3}
              sx={{ position: "absolute", top: "40px", zIndex: "999999999" }}
            >
              <Collapse in={show}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="error"
                      size="small"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  Admin deleted successfully
                </Alert>
              </Collapse>
            </Grid>
          </Grid>

          <Grid sx={{ display: "flex", justifyContent: "end" }}>
            <Link to="/addadmin">
              <AddCircleIcon color="info" />
            </Link>
          </Grid>
          <Typography variant="h4" align="center">
            Admin Listing
          </Typography>

          {loader ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : allAdmins.length !== 0 ? (
            <TableContainer
              component={Paper}
              sx={{
                width: "100%",
                bgcolor: "white",
                mt: 1,
                boxShadow: "0 0 5px 0 #888888",
              }}
            >
              <Table sx={{ width: "100%", background: "white" }}>
                <TableHead sx={{ width: "100%" }}>
                  <TableRow>
                    <TableCell>
                      <b>Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>

                    <TableCell>
                      <b>Action</b>
                    </TableCell>
                    <TableCell>
                      <b>Reset Cred</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allAdmins.map((res, index) => {
                    return (
                      <TableRow key={index} sx={{ width: "100%" }}>

                        <TableCell>{res.admin_name}</TableCell>
                        <TableCell>
                          {res.email}
                        </TableCell>

                        <TableCell>
                          <RemoveRedEye
                            sx={{ color: "#FFB800", cursor: "pointer" }}
                            onClick={() => navigate(`/viewadmin/${res._id}`)}
                          />
                          <Edit
                            sx={{ color: "#169AC9", cursor: "pointer" }}
                            onClick={() => navigate(`/editadmin/${res._id}`)}
                          />

                          <DeleteConfirmComp msg='Are your want to delete Admin ?' id={res._id} deleteFun={deleteAdmin} />

                        </TableCell>

                        <TableCell>
                          <ResetPassword
                            roomId={res._id}
                            resetPasswordSubmit={resetAdminPassword}
                          />
                        </TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
            </TableContainer>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "150px",
              }}
            >
              <img src="images/noData.png" alt="" style={{ width: "100px" }} />
            </div>
          )}
        </div>
      </Sidebar>
    </>
  );
};
