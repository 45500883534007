import { Search } from "@mui/icons-material";
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Backdrop,
  CircularProgress,
  Typography,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState, createContext } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Box } from "@mui/system";
import { baseURL } from "../../auth/GlobalUrl";
import { io } from "socket.io-client"

export const UserId = createContext();
export const Assessment = () => {
  let { id } = useParams();
  
  var socket = io('https://childcare-714af9baa16a.herokuapp.com', {
      auth: {
          id
      },

  })
  localStorage.setItem("id", id);

  let [loader, setLoader] = useState(true);
  let [allRooms, setAllRooms] = useState([]);
  let [all, setAll] = useState([]);
  console.log(all)
  let [noData, setNoData] = useState(false);

  function getAllChild() {
    setNoData(false);
    let url = `${baseURL}teacher/getTeacher/${id}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.data.length !== 0) {
          console.log(res.data.data);
          setAllRooms(res.data.data[0].room_data);
          setAll(res.data.data[0].room_data);
          setLoader(false);
        } else {
          setLoader(false);
          setNoData(true);
        }
      })
      .catch((err) => console.log(err));
  }

  const onSearch = (e) => {
    e.target.value == " "
      ? (e.target.value = "")
      : setAllRooms(
        all.filter((res) =>
          res.room_name.toLowerCase().includes(e.target.value.toLowerCase().trim())
        )
      );
  };

  useEffect(() => {
    getAllChild();
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    }
  }, []);

  return (
    <>
      <Sidebar>
        <Grid container px={2}>
          <Grid item md={5} sm={5} xs={12}>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search Name"
              size="medium"
              onChange={onSearch}
              sx={{ backgroundColor: "white", width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {loader ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Box sx={{ mx: 2, my: 2, bgcolor: "white" }}>
            {noData ? (
              <Box>No data available...</Box>
            ) : (
              <TableContainer sx={{ boxShadow: "0 0 5px 0 #888888" }}>
                <Table sx={{ overflow: 'scroll' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No.</TableCell>
                      <TableCell>Room Name</TableCell>
                      <TableCell>Number of Childs</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allRooms.length !== 0 ? allRooms.map((res, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{res.room_name}</TableCell>
                          <TableCell>{res.room_childArray.length}</TableCell>
                          <TableCell>
                            <Link style={{ textDecoration: 'none' }} to={`/allchildofroom/${res._id}`}>
                              <Button className="btn_primary">Visit Room</Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    }) : <TableRow ><Typography m={3}>No Room Found</Typography></TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        )}
      </Sidebar>
    </>
  );
};
